import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
	display: inline-flex;
	align-items: flex-start;
	line-height: 24px;
	font-size: 17px;
	margin-bottom: 20px;
	user-select: none;
	cursor: pointer;
	width: -webkit-fill-available;

	input {
		position: absolute;
		opacity: 0;
		height: 1px;
		width: 1px;
		margin: 0;
	}

	.checkmark {
		position: relative;
		height: 24px;
		flex: 0 0 24px;
		margin-right: 10px;
		border-radius: ${p => p.theme.utils.borderRadius};
		border: 2px solid ${p => p.theme.colors.grey500};
		background-color: white;
		transition: all 0.2s ease-in-out;

		&:after {
			content: '';
			position: absolute;
			display: none;
			left: 8px;
			top: 4px;
			width: 5px;
			height: 9px;
			border-width: 0 2px 2px 0;
			border-style: solid;
			border-color: ${p => p.theme.colors.grey500};
			transform: rotate(45deg);
		}
	}

	&:hover input:not(:disabled) ~ .checkmark {
		background-color: white;
		border-color: ${p => p.theme.colors.grey700};
		&:after {
			display: block;
		}
	}

	input:checked ~ .checkmark {
		background-color: ${p => p.theme.colors.blue600};
		border-color: ${p => p.theme.colors.blue600};

		&:after {
			border-color: white;
			display: block;
		}
	}

	&:hover input:checked ~ .checkmark {
		background-color: ${p => p.theme.colors.blue700};
		border-color: ${p => p.theme.colors.blue700};

		&:after {
			left: 9px;
			top: 4px;
			width: 0px;
			height: 11px;
			border-width: 0 2px 0 0;
			transform: rotate(90deg);
		}
	}

	input:disabled ~ .label {
		cursor: no-drop;
		color: ${p => p.theme.colors.grey500};
	}
	input:disabled ~ .checkmark {
		cursor: no-drop;
		border-color: ${p => p.theme.colors.grey500};
	}
`;

const HelpText = styled.div`
	color: ${p => p.theme.colors.grey700};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin-top: 5px;
`;

const Checkbox = forwardRef(({ label, children, helpText, ...props }, ref) => {
	return (
		<Wrapper data-cy={`${props.name}-label`}>
			<input type="checkbox" {...props} ref={ref} />

			<div className="checkmark" />

			<div className="label">
				<span>{label || children}</span>
				{helpText && <HelpText>{helpText}</HelpText>}
			</div>
		</Wrapper>
	);
});

export default Checkbox;
