import React, { useState, forwardRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons/faCircleNotch';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0 0 20px;

	${p =>
		p.theme.media.XSmallOnly(css`
			margin: 0 0 15px;
		`)}

	textarea,
	input {
		margin: 0;
		display: flex;
		flex-direction: column;
		height: 56px;
		padding: 15px 20px;
		width: auto;
		border-radius: ${p => p.theme.utils.borderRadius};
		border: none;
		outline: 1px solid ${p => p.theme.colors.grey400};
		background: ${p => p.theme.colors.grey200};
		transition: all 0.2s ease-out;
		line-height: 26px;
		outline-offset: -2px;

		&::-webkit-search-cancel-button {
			appearance: none;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px white inset !important;
		}

		&:-webkit-autofill {
			background: ${p => p.theme.colors.white};
			outline: 1px solid ${p => p.theme.colors.grey900};
			~ label {
				background-color: ${p => p.theme.colors.white};
				padding: 0 5px;
				color: ${p => p.theme.colors.grey900};
				line-height: 22px;
				transform: translate(15px, -9px) scale(0.82);
			}
		}

		${p =>
			p.theme.media.XSmallOnly(css`
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			`)}
	}
	textarea {
		height: auto;
		min-height: 150px;

		&:-webkit-autofill {
			background: ${p => p.theme.colors.white};
			outline: 1px solid ${p => p.theme.colors.grey900};
			~ label {
				background-color: ${p => p.theme.colors.white};
				padding: 0 5px;
				color: ${p => p.theme.colors.grey900};
				line-height: 22px;
				transform: translate(15px, -9px) scale(0.82);
			}
		}
	}

	label {
		color: ${p => p.theme.colors.grey700};
		font-size: 17px;
		font-weight: 400;
		line-height: 26px;
		pointer-events: none;
		position: absolute;
		transform: translate(20px, 15px) scale(1);
		transform-origin: top left;
		transition: all 0.2s ease-out;

		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: calc(100% - 60px);

		.required-indicator {
			color: ${p => p.theme.colors.coral900};
			margin-left: 5px;
		}
	}

	svg {
		color: ${p => p.theme.colors.grey700};
	}

	&.icon:not(.leading-icon) {
		input {
			padding: 19px 38px 19px 20px;
		}
	}

	&.leading-icon {
		textarea,
		input {
			padding: 19px 20px 19px 52px;
		}

		label {
			transform: translate(52px, 15px) scale(1);
		}
	}

	&:hover {
		textarea,
		input {
			outline: 1px solid ${p => p.theme.colors.grey700};
		}
	}

	&.active {
		textarea,
		input {
			background: ${p => p.theme.colors.white};
			outline: 1px solid ${p => p.theme.colors.grey900};
		}
		label {
			background-color: ${p => p.theme.colors.white};
			padding: 0 5px;
			color: ${p => p.theme.colors.grey900};
			line-height: 22px;
			transform: translate(15px, -9px) scale(0.82);
		}
	}
	&:focus-within {
		textarea,
		input {
			background: ${p => p.theme.colors.white};
			outline: 2px solid ${p => p.theme.colors.blue600};
		}
		label {
			background-color: ${p => p.theme.colors.white};
			padding: 0 5px;
			color: ${p => p.theme.colors.grey900};
			line-height: 22px;
			transform: translate(15px, -9px) scale(0.82);
		}
		svg {
			color: ${p => p.theme.colors.grey900};
		}
	}
	&.help-text {
		margin-bottom: 47px;
	}
	&.error {
		margin-bottom: 47px;
		textarea,
		input {
			background: ${p => p.theme.colors.white};
			outline: 2px solid ${p => p.theme.colors.coral900};
		}
		label {
			background-color: ${p => p.theme.colors.white};
			padding: 0 5px;
			color: ${p => p.theme.colors.coral900};
			line-height: 22px;
			transform: translate(15px, -9px) scale(0.82);
		}
		svg {
			color: ${p => p.theme.colors.coral900};
		}
	}
`;
export const IconWrap = styled.span`
	position: absolute;
	right: 20px;
	top: 0;
	height: 56px;
	width: 30px;
	font-size: 17px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	text-align: center;
	pointer-events: none;

	.leading-icon & {
		right: auto;
		left: 20px;
		justify-content: flex-start;
	}

	// Hide when loading-icon is overlapping
	.loading:not(.leading-icon) & {
		display: none;
	}
`;

export const LoadingWrap = styled.span`
	position: absolute;
	right: 20px;
	top: 0;
	height: 56px;
	width: 30px;
	font-size: 20px;
	display: none;
	align-items: center;
	justify-content: flex-end;
	text-align: center;

	// When no leading-icon then place to left
	&:not(.leading-icon) & {
		right: auto;
		left: 20px;
		justify-content: flex-start;
	}

	.loading & {
		display: flex;
	}
`;

export const ClearWrap = styled.div`
	position: absolute;
	right: 20px;
	top: 0px;
	height: 100%;
	width: 30px;
	font-size: 17px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	text-align: center;
`;

export const IconLoading = styled(FontAwesomeIcon)`
	font-size: 17px;
	animation-duration: 1s;
	color: ${p => p.theme.colors.grey700};
`;

export const Error = styled.span`
	position: absolute;
	left: 0;
	top: calc(100% + 5px);
	color: ${p => p.theme.colors.coral900};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
`;

export const HelpText = styled(Error)`
	color: ${p => p.theme.colors.grey700};
`;

const InputField = forwardRef(
	(
		{
			label,
			placeholder,
			error,
			clear,
			loading = false,
			helpText,
			icon,
			textarea = false,
			leadingIcon = false,
			className,
			...props
		},
		ref
	) => {
		const [isActive, setIsActive] = useState(
			!!props.defaultValue || !!props.value
		);

		let classes = isActive ? 'active' : '';
		if (error) {
			classes += ' error';
		}
		if (helpText) {
			classes += ' help-text';
		}
		if (icon) {
			classes += ' icon';
		}
		if (leadingIcon) {
			classes += ' leading-icon';
		}
		if (loading) {
			classes += ' loading';
		}
		if (className) {
			classes += ` ${className}`;
		}

		const text = label || placeholder;

		const Icon = icon?.prefix ? FontAwesomeIcon : icon;

		// If input value is 0 then reset active-state
		useEffect(() => {
			if (!ref?.current) return;
			if (ref?.current?.value?.length === 0) setIsActive(false);
			//eslint-disable-next-line
		}, [ref?.current?.value?.length]);

		useEffect(() => {
			if (!!props?.value || !!props?.defaultValue) setIsActive(true);
			//eslint-disable-next-line
		}, [props?.value, props?.defaultValue]);

		return (
			<Wrapper className={classes}>
				{textarea ? (
					<textarea
						rows={props.rows || 4}
						onFocus={e => setIsActive(!!e.target.value)}
						onBlur={e => setIsActive(!!e.target.value)}
						id={props.id || props.name}
						ref={ref}
						{...props}
					/>
				) : (
					<input
						type={props.type || 'text'}
						onFocus={e => setIsActive(!!e.target.value)}
						onBlur={e => setIsActive(!!e.target.value)}
						id={props.id || props.name}
						ref={ref}
						{...props}
					/>
				)}

				{text && (
					<label htmlFor={props.id || props.name}>
						{text}

						{props.required && (
							<span className="required-indicator">*</span>
						)}
					</label>
				)}

				{!error && icon && (
					<IconWrap className="icon-wrapper">
						{icon?.prefix ? <Icon icon={icon} /> : <Icon />}
					</IconWrap>
				)}

				{(loading && (
					<LoadingWrap className="loading-wrapper">
						<IconLoading
							icon={faCircleNotch}
							className="fa-spin"
							width="20"
							height="20"
						/>
					</LoadingWrap>
				)) ||
					(clear && (
						<ClearWrap className="clear-wrapper">{clear}</ClearWrap>
					))}

				{error && (
					<>
						<IconWrap>
							<FontAwesomeIcon icon={faCircleExclamation} />
						</IconWrap>

						<Error>{error?.message || error}</Error>
					</>
				)}

				{!error && helpText && <HelpText>{helpText}</HelpText>}
			</Wrapper>
		);
	}
);

export default InputField;
