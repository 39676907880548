import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';

/* Init dayjs plugin */
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const DatePickerContext = createContext({});
export const useDatePicker = () => useContext(DatePickerContext);

export default function DatePickerProvider({ children, value = null }) {
	const [date, setDate] = useState(value);
	const [temporaryDate, setTemporaryDate] = useState(dayjs());
	const [currentView, setCurrentView] = useState('day');
	const [showCalendar, setShowCalendar] = useState(false);

	/**
	 * Toggle visibility of the calendar
	 * @returns {void}
	 **/
	const toggleCalendar = () => {
		setShowCalendar(!showCalendar);
	};

	/**
	 * Check if the date is not included in the array of dates
	 * @param {array} dates - The array of dates
	 * @param {string} date - The date
	 * @param {string} dateFormat - The date format
	 * @returns {boolean} - The result
	 **/
	const dateIsNotIncluded = (dates, date, dateFormat = 'YYYYMMDD') => {
		if (!dates?.length > 0 && !date) return false;
		return !dates?.includes(dayjs(date).format(dateFormat));
	};

	/**
	 * Disable previous navigation arrow
	 * @param {string} type - The type (day/month/year)
	 * @param {object} props - The props
	 * @returns {boolean} - The result
	 */
	function disablePreviousNav({ type = 'day', ...props }) {
		const currentDate = temporaryDate || date;

		// If block past dates
		if (props?.blockPastDates) {
			if (type === 'day' && dayjs().isSameOrAfter(currentDate, 'month')) {
				return true;
			}
			if (type !== 'day' && dayjs().isAfter(currentDate, type)) {
				return true;
			}
			if (!props?.minDate) return false;
		}

		// If has max-date (for example only allow 3 months ahead of today)
		if (props?.maxDate) {
			if (
				type === 'day' &&
				dayjs(props?.maxDate).isSameOrBefore(currentDate, 'month')
			) {
				return true;
			}

			if (dayjs(props?.maxDate).isBefore(currentDate, type)) {
				return true;
			}

			if (!props?.blockFutureDates) return false;
		}
		// If has min-date (age-limit for example)
		if (props?.minDate) {
			if (
				type === 'day' &&
				dayjs(props?.minDate).isBefore(currentDate, 'month')
			) {
				return true;
			}

			if (dayjs(props?.minDate).isBefore(currentDate, type)) {
				return true;
			}

			if (!props?.blockPastDates) return false;
		}

		// If array of available-dates
		if (props?.availableDates?.length > 0) {
			if (
				!props?.availableDates?.some(availableDate =>
					dayjs(currentDate).isBefore(dayjs(availableDate), 'month')
				)
			) {
				return true;
			}
			return false;
		}
	}

	/**
	 * Disable next navigation arrow
	 * @param {string} type - The type (day/month/year)
	 * @param {object} props - The props
	 * @returns {boolean} - The result
	 */
	function disableNextNav({ type = 'day', ...props }) {
		const currentDate = temporaryDate || date;

		// If block future dates
		if (props?.blockFutureDates) {
			if (
				type === 'day' &&
				dayjs().isSameOrBefore(currentDate, 'month')
			) {
				return true;
			}
			if (type !== 'day' && dayjs().isBefore(currentDate, type)) {
				return true;
			}
			if (!props?.maxDate) return false;
		}

		// If has min-date (age-limit for example)
		if (props?.minDate) {
			if (
				type === 'day' &&
				dayjs(props?.minDate).isSameOrBefore(currentDate, 'month')
			) {
				return true;
			}

			if (dayjs(props?.minDate).isBefore(currentDate, type)) {
				return true;
			}

			if (!props?.blockPastDates) return false;
		}

		// If has max-date (for example only allow 3 months ahead of today)
		if (props?.maxDate) {
			if (
				type === 'day' &&
				dayjs(props?.maxDate).isSameOrBefore(currentDate, 'month')
			) {
				return true;
			}

			if (dayjs(props?.maxDate).isBefore(currentDate, type)) {
				return true;
			}

			if (!props?.blockFutureDates) return false;
		}

		// If array of available-dates
		if (props?.availableDates?.length > 0) {
			if (
				!props?.availableDates?.some(availableDate =>
					dayjs(currentDate).isAfter(dayjs(availableDate), 'month')
				)
			) {
				return true;
			}
			return false;
		}
	}

	/**
	 * Check if date is disabled
	 * @param {object} date - The date
	 * @param {string} type - The type (day/month/year)
	 * @param {object} props - The props
	 * @returns {boolean} - The result
	 **/
	function isDateDisabled({ date, type = 'day', ...props }) {
		if (!date) return;

		// Block tomorrow's date
		const blockTomorrowsDate =
			(type === 'day' &&
				props?.blockTomorrow &&
				date?.isSame(dayjs().add(1, 'day'), 'day')) ||
			false;

		// Block all elements (days/months/years) in the past
		const blockPastDates =
			(props?.blockPastDates &&
				type === 'day' &&
				dayjs().isSameOrAfter(date, type)) ||
			(props?.blockPastDates &&
				type !== 'day' &&
				dayjs().isAfter(date, type)) ||
			false;

		// Block all elements (days/months/years) in the future
		const blockFutureDates =
			(props?.blockFutureDates &&
				type === 'day' &&
				dayjs().isSameOrBefore(date, type)) ||
			(props?.blockFutureDates &&
				type !== 'day' &&
				dayjs().isBefore(date, type)) ||
			false;

		let blockDate = false;

		// If max-date is provided then block past elements (days/months/years) before that
		if (props?.maxDate) {
			blockDate = dayjs(props?.maxDate).isSameOrBefore(date, type);

			// month-view
			if (type === 'month') {
				blockDate = dayjs(props?.maxDate).isBefore(date, 'month');
			}

			// year-view
			if (type === 'year') {
				blockDate = dayjs(props?.maxDate).isBefore(date, 'year');
			}
		}

		// If min-date (age-limit for example) is provided then block future elements (days/months/years) preceding that date
		if (props?.minDate) {
			blockDate = dayjs(props?.minDate).isSameOrBefore(date, type);

			// month-view
			if (type === 'month') {
				blockDate = dayjs(props?.minDate).isBefore(date, 'month');
			}

			// year-view
			if (type === 'year') {
				blockDate = dayjs(props?.minDate).isBefore(date, 'year');
			}
		}

		/*
		 * If array of available-dates have any datestrings
		 * detect if the current day is not part of the array
		 */
		if (type === 'day' && props?.availableDates?.length > 0) {
			blockDate = dateIsNotIncluded(props?.availableDates, date);
		}

		// Return if date is disabled
		return (
			!date ||
			blockTomorrowsDate ||
			blockPastDates ||
			blockFutureDates ||
			blockDate
		);
	}

	return (
		<DatePickerContext.Provider
			value={{
				date,
				setDate,
				temporaryDate,
				setTemporaryDate,
				showCalendar,
				setShowCalendar,
				currentView,
				setCurrentView,

				// Toggle calendar visibility
				toggleCalendar,

				// Toggle calendar visibility on keypress
				toggleOnKeyPress: e => {
					const key = e?.key;
					if (key === 'Space' || key === 'Enter') {
						toggleCalendar();
					}
				},

				// Close calendar
				closeCalendar: () => {
					setShowCalendar(false);
				},

				// Handle select date
				handleSelectDate: ({ value, closeOnClick = false }) => {
					setDate(value);
					setTemporaryDate(dayjs(value));
					if (closeOnClick) setShowCalendar(false);
				},

				// Set previous year based on temporaryDate
				setPreviousYear: () => {
					if (!temporaryDate) return;
					setTemporaryDate(
						temporaryDate.year(temporaryDate.year() - 1).date(1)
					);
				},

				// Set next year based on temporaryDate
				setNextYear: () => {
					if (!temporaryDate) return;
					setTemporaryDate(
						temporaryDate.year(temporaryDate.year() + 1).date(1)
					);
				},

				// Set previous month based on temporaryDate
				setPreviousMonth: () => {
					if (!temporaryDate) return;
					setTemporaryDate(
						temporaryDate?.month(temporaryDate.month() - 1).date(1)
					);
				},

				// Set next month based on temporaryDate
				setNextMonth: () => {
					if (!temporaryDate) return;
					setTemporaryDate(
						temporaryDate.month(temporaryDate.month() + 1).date(1)
					);
				},

				// Handle keypress
				handleKeyPress: (e, cb) => {
					const charCode = e.charCode;
					if ((charCode === 13 && cb) || (charCode === 32 && cb)) {
						cb();
					}
				},

				// Get the current year, either the one from temporaryDate-state or today´s current year
				getThisYear: date =>
					!date ? dayjs().get('year') : dayjs(date).get('year'),

				// Check if two dates are the same
				isSameDate: (date1, date2) => dayjs(date1).isSame(dayjs(date2)),

				// Check if the current day is same or after day
				isSameOrAfterDay: day =>
					dayjs(dayjs()).isSameOrAfter(day, 'day'),

				// Check if the current day is same or before day
				isSameOrBeforeDay: day =>
					dayjs(dayjs()).isSameOrBefore(day, 'day'),

				// Check if the date exists in the array of years
				dateExistsInYearsArray: (date, yearsArray) => {
					if (!date || !yearsArray?.length > 0) return;
					return yearsArray.some(year => {
						const startOfYear = dayjs(`${year}-01-01`);
						const endOfYear = dayjs(`${year}-12-31`);
						return date?.isBetween(
							startOfYear,
							endOfYear,
							null,
							'[]'
						); // '[]' includes start and end dates
					});
				},

				dateIsNotIncluded,
				isDateDisabled,
				disablePreviousNav,
				disableNextNav,
			}}>
			{children}
		</DatePickerContext.Provider>
	);
}
