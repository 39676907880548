import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/pro-solid-svg-icons/faAnglesRight';

import { useDatePicker } from './Provider';
import Arrow from 'images/icons/ArrowRight';
import BlankButton from '../BlankButton';

const Nav = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	background-color: transparent;
	color: ${p => p.theme.colors.black};
`;
const Column = styled.div`
	display: flex;
	flex-direction: row;
`;
const IconContainer = styled.div`
	display: flex;
	width: 38px;
	height: 38px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	${p =>
		p.disabled &&
		css`
			cursor: no-drop;
			svg {
				color: ${p => p.theme.colors.grey500};
				fill: ${p => p.theme.colors.grey500};
			}
		`};
`;
const ButtonHeading = styled.div`
	margin: 0 10px;
	font-size: 17px;
	line-height: 29px;
	font-weight: 500;
	text-align: center;
	button {
		color: ${p => p.theme.colors.black};
		text-decoration: none;
		text-transform: capitalize;
		font-weight: 500;
	}
	span   {
		text-transform: capitalize;
	}
`;
const ArrowIcon = styled(Arrow)`
	transform: ${p => (p.rotate === 'true' ? 'rotate(180deg)' : 'none')};
	width: 9px;
	height: 29px;
	color: ${p => p.theme.colors.black};
`;

const DoubleArrowIcon = styled(FontAwesomeIcon)`
	transform: ${p => (p.rotate === 'true' ? 'rotate(180deg)' : 'none')};
	width: 9px;
	height: 29px;
	color: ${p => p.theme.colors.black};
`;

export default function Navigation({
	buttonText = '',
	toggle = true,
	changeViewTo = 'day',
	prevDoubleArrow,
	nextDoubleArrow,
	prevArrow,
	nextArrow,
	disableNextArrow,
	disablePrevArrow,
	disableChangeView = false,
}) {
	const {
		setCurrentView,
		setPreviousYear,
		setNextYear,
		setPreviousMonth,
		setNextMonth,
		handleKeyPress,
	} = useDatePicker();

	const showLeftColumn =
		(handleKeyPress && setPreviousYear) ||
		(handleKeyPress && setPreviousMonth);
	const showRightColumn =
		(handleKeyPress && setNextYear) || (handleKeyPress && setNextMonth);

	return (
		<Nav>
			{showLeftColumn && (
				<Column>
					{prevDoubleArrow && (
						<IconContainer
							role="button"
							tabIndex="0"
							aria-label={prevDoubleArrow.ariaLabel}
							disabled={
								disablePrevArrow || prevDoubleArrow.blocked
							}
							data-cy="datepicker-double-prev"
							{...(!disablePrevArrow &&
								!prevDoubleArrow.blocked && {
									onClick: prevDoubleArrow.onClick,
									onKeyPress: prevDoubleArrow.onKeyPress,
								})}>
							<DoubleArrowIcon
								rotate="true"
								icon={faAnglesRight}
							/>
						</IconContainer>
					)}

					{prevArrow && (
						<IconContainer
							role="button"
							tabIndex="0"
							aria-label={prevArrow.ariaLabel}
							disabled={disablePrevArrow || prevArrow.blocked}
							data-cy="datepicker-prev"
							{...(!disablePrevArrow &&
								!prevArrow.blocked && {
									onClick: prevArrow.onClick,
									onKeyPress: prevArrow.onKeyPress,
								})}>
							<ArrowIcon rotate="true" />
						</IconContainer>
					)}
				</Column>
			)}

			{buttonText && (
				<ButtonHeading role="heading">
					{toggle && !disableChangeView ? (
						<BlankButton
							onClick={() => setCurrentView(changeViewTo)}
							data-cy="datepicker-toggle-view"
							title={buttonText}
						/>
					) : (
						<span>{buttonText}</span>
					)}
				</ButtonHeading>
			)}

			{showRightColumn && (
				<Column>
					{nextArrow && (
						<IconContainer
							role="button"
							tabIndex="0"
							aria-label={nextArrow.ariaLabel}
							data-cy="datepicker-next"
							disabled={disableNextArrow || nextArrow.blocked}
							{...(!disableNextArrow &&
								!nextArrow.blocked && {
									onClick: nextArrow.onClick,
									onKeyPress: nextArrow.onKeyPress,
								})}>
							<ArrowIcon />
						</IconContainer>
					)}

					{nextDoubleArrow && (
						<IconContainer
							role="button"
							tabIndex="0"
							data-cy="datepicker-double-next"
							aria-label={nextDoubleArrow.ariaLabel}
							disabled={
								disableNextArrow || nextDoubleArrow.blocked
							}
							{...(!disableNextArrow &&
								!nextDoubleArrow.blocked && {
									onClick: nextDoubleArrow.onClick,
									onKeyPress: nextDoubleArrow.onKeyPress,
								})}>
							<DoubleArrowIcon icon={faAnglesRight} />
						</IconContainer>
					)}
				</Column>
			)}
		</Nav>
	);
}
