import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';

import LeadForm from './LeadForm';

const SUBMIT_MARKETING_CONSENT = gql`
	mutation submitMarketingConsent(
		$event: String!
		$email: String!
		$currentUrl: String
		$entry: String!
	) {
		success: submitMarketingConsent(
			event: $event
			email: $email
			currentUrl: $currentUrl
			entry: $entry
		)
	}
`;

/**
 * Represents a custom marketing consent form
 * @param {...object} props - The properties for the form
 * @param {string} [title='Vil du motta tilbud, informasjon og tips fra NTE elektronisk?'] - The title of the form
 * @param {string} [text=''] - The text of the form
 * @param {string} [submitText='Ja takk!'] - The submit text of the form
 * @param {string} [className='consent-form'] - The class name of the form
 * @param {string} [subscription='Markedsføring'] - The subscription type title
 * @param {object} [submitTexts] - The submit texts of the form
 * @param {string} [event='Form Submitted'] - The event to track
 * @param {string} [customDisclaimer] - The custom disclaimer of the form
 * @param {object} [formProps] - The form properties
 * @returns {JSX.Element} - The form
 * @throws {error} - The error from the backend
 **/
export default function ConsentForm({
	title = 'Vil du motta tilbud, informasjon og tips fra NTE elektronisk?',
	text = '',
	className = 'consent-form',
	subscription = 'Markedsføring',
	event = 'Form Submitted',
	submitTexts = {
		submitting: 'Lagrer',
		submit: 'Ja takk!',
		submitted: 'Tusen takk for din påmelding!',
	},
	customDisclaimer,
	formProps,
	...props
}) {
	const [saving, setSaving] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	// Mutation to submit consent (track event)
	const [submitMarketingConsent, { loading, error }] = useMutation(
		SUBMIT_MARKETING_CONSENT
	);

	async function onSubmit(values) {
		try {
			if (!values?.email) throw new Error('E-mail is required');

			setSaving(true);

			const response = await submitMarketingConsent({
				variables: {
					event,
					email: values?.email,
					currentUrl: window?.location?.href,
					entry: `Nytt samtykke for ${subscription} - ${document?.title}`,
				},
			});

			if (!response?.data?.success) {
				throw new Error('Consent subscription update failed');
			}

			setSaving(false);
			setSubmitted(true);
			return response?.data?.success;
		} catch (e) {
			setSaving(false);
			console.log(`ConsentForm Error: ${e?.message}`);
		}
	}

	return (
		<LeadForm
			title={title}
			text={text || props?.intro}
			className={className}
			fields={['email']}
			customDisclaimer={customDisclaimer}
			onSubmit={onSubmit}
			subscription={subscription}
			loading={loading || saving}
			error={error}
			resolver="submitMarketingConsent"
			submitTexts={submitTexts}
			submitted={submitted}
			{...props}
		/>
	);
}
