import { useEffect, useState } from 'react';

import { isDateValid, isLegalAge, scrollToElement } from 'libs/content';
import { isDateInFuture } from 'components/fiber-2.0/Provider';

/**
 * A hook for input validation
 * @returns {Object} - The errors state and the validate function
 */
export default function useInputValidation() {
	const [errors, setErrors] = useState([]);

	/**
	 * Validates the input value and sets the errors state
	 * @param {string} field - The id of the input field
	 * @param {string} value - The value of the input field
	 * @param  {...any} props - Additional properties
	 */
	const validate = ({ field, value, ...props }) => {
		//console.log(field, value, props);

		setErrors(prev => prev.filter(error => error?.field !== field));

		// Check if the value is missing
		if (!value) {
			// dont add error if the field is already in the errors array
			if (errors?.some(error => error?.field === field)) return;

			setErrors(prev => [
				...prev,
				{
					field,
					error: 'Mangler verdi',
				},
			]);
		}

		// Check if the value is a valid telephone number
		if (field === 'telefon') {
			const phoneRegex =
				/^((0047)?|(\+47)?|(47)?)?(\d{2}\s\d{2}\s\d{2}\s\d{2}|\d{8})$/; // Norwegian phone number, with or without country code, with or without spaces

			if (!phoneRegex.test(value)) {
				// dont add error if the field is already in the errors array
				if (errors?.some(error => error?.field === field)) return;

				setErrors(prev => [
					...prev,
					{
						field,
						value,
						error: 'Ikke gyldig telefonnummer',
					},
				]);
			}
		}

		// Check if the value is a valid email address
		if (field === 'epost') {
			const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailRegex.test(value)) {
				// dont add error if the field is already in the errors array
				if (errors?.some(error => error?.field === field)) return;

				setErrors(prev => [
					...prev,
					{
						field,
						value,
						error: 'Ikke gyldig e-postadresse',
					},
				]);
			}
		}

		// Check if the date format is valid
		if (props?.dateFormat && !isDateValid(value, props?.dateFormat)) {
			// dont add error if the field is already in the errors array
			if (errors?.some(error => error?.field === field)) return;

			setErrors(prev => [
				...prev,
				{
					field,
					value,
					error: `Ugylig datoformat, må være ${props?.dateFormat}`,
					format: props?.dateFormat,
				},
			]);
		}

		// Check if the date is legal age
		if (props?.minAge && !isLegalAge(value)) {
			// dont add error if the field is already in the errors array
			if (errors?.some(error => error?.field === field)) return;

			setErrors(prev => [
				...prev,
				{
					field,
					value,
					error: 'Må være minimum 18 år',
				},
			]);
		}

		// Check if the date is in the future
		if (props?.mustBeFutureDate && !isDateInFuture(value)) {
			// dont add error if the field is already in the errors array
			if (errors?.some(error => error?.field === field)) return;

			setErrors(prev => [
				...prev,
				{
					field,
					value,
					error: 'Ikke gyldig dato, må være i fremtiden',
				},
			]);
		}
	};

	/**
	 * Scrolls to the first error in the form if there are any and finds the element by id
	 */
	const scrollToError = () => {
		if (!errors?.length > 0) return;

		const firstError = errors?.find(error => error?.field);
		const domElement = document?.querySelector(`#${firstError?.field}`);
		if (domElement) scrollToElement(domElement, 300);
	};

	/**
	 * Check if an error exists for a specific input.
	 * @param {string} field - The id of the input field
	 * @param {Array} errorList - The errors array
	 * @returns {boolean} - Indicates if the error exists.
	 **/
	const hasError = (field, errorList) => {
		if (!field || !errorList?.length > 0) return false;
		return errorList?.some(e => e?.field === field && !!e?.error);
	};

	return { errors, validate, scrollToError, hasError };
}

/**
 * Get the accepted fields.
 * @param {Array} fields - The values to check.
 * @returns {boolean} - Indicates if the fields are accepted.
 **/
export function useAcceptedFields(fields) {
	const [readyToSubmit, setReadyToSubmit] = useState(false);

	useEffect(() => {
		// If no values, exit the function
		if (!fields?.length > 0) return;

		// Check if any of the required fields are not true
		const falseFields = fields?.some(f => f?.value !== true);

		if (falseFields) {
			//console.error('Not all required fields are accepted');
			setReadyToSubmit(false);
		} else {
			setReadyToSubmit(true);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...fields]);

	return readyToSubmit;
}

/**
 * Check if all required fields have values.
 * @param {Array} fields - The fields to check.
 * @returns {boolean} - Indicates if all required fields have values.
 */
export function useFieldsHaveValues(fields) {
	const [readyToSubmit, setReadyToSubmit] = useState(false);

	useEffect(() => {
		// If no values, exit the function
		if (!fields?.length > 0) return;

		// Check if any of the required fields are not true
		const fieldsWithValue = fields?.every(
			field => typeof field === 'string' && field?.length > 0
		);

		console.log(fields);

		if (fieldsWithValue) {
			setReadyToSubmit(true);
		} else {
			//console.error('Not all required fields have values');
			setReadyToSubmit(false);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...fields]);

	return readyToSubmit;
}
