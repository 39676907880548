import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
	> div {
		margin-bottom: 10px;
	}
	${p =>
		p.theme.media.smallUp(css`
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: 5px;
			border-radius: ${p => p.theme.utils.borderRadius};
			outline: 1px solid ${p => p.theme.colors.grey400};
			background: ${p => p.theme.colors.grey200};

			&:hover {
				outline: 1px solid ${p => p.theme.colors.grey700};
			}

			// When inner div has class "error" and "help-text" style the inner div
			.error,
			.help-text {
				margin-bottom: 0 !important;
			}

			// When inner div has class "error" style the Wrapper
			&:has(> div.error) {
				margin-bottom: 47px;
			}

			// When inner div has class "active" style the wrapper
			&:has(> div.active) {
				background: #fff !important;
				outline: 1px solid ${p => p.theme.colors.grey900} !important;
				input,
				textarea {
					background-color: transparent;
					border: 0;
					outline: 0;
				}
			}
			// When inner div has class "error" style the wrapper
			&:has(> div.error) {
				background: #fff !important;
				outline: 2px solid ${p => p.theme.colors.coral900} !important;
				input,
				textarea {
					background-color: transparent;
					border: 0;
					outline: 0;
				}
			}
			&:focus-within {
				background: ${p => p.theme.colors.white};
				outline: 2px solid ${p => p.theme.colors.blue600};
			}

			> div {
				flex-grow: 1;
				margin: 0;
				input,
				textarea,
				&.active {
					background-color: transparent;
					border: 0;
					outline: 0;
					&:focus,
					&:hover,
					&:focus-within {
						background-color: transparent !important;
						border: 0 !important;
						outline: 0 !important;
					}
				}
			}
		`)}
`;

export default function InputAndButtonWrap({ children, ...props }) {
	return <Wrapper {...props}>{children}</Wrapper>;
}
