import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import chunk from 'lodash/chunk';
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';

import Navigation from './Navigation';
import { useDatePicker } from './Provider';

/* Init dayjs plugin */
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const Wrapper = styled.div`
	width: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	margin: 20px 0;
	> * {
		box-sizing: border-box;
	}
`;
const Table = styled.div`
	margin: 20px 0 0;
`;
const Head = styled.div`
	display: flex;
`;
const CellStyle = () => `
	text-align: center;
	font-weight: 400;
	font-size: 17px;
	height: 39px;
	background: transparent;
	width: calc(100% / 7 + 1px);
	margin-top: -1px;
	margin-left: -1px;
`;

const Heading = styled.div`
	${CellStyle}
	height: auto;
	padding: 5px 0;

	abbr {
		text-decoration: none;
		color: ${p => p.theme.colors.grey900};
	}
`;

const Cell = styled.button`
	${CellStyle}

	border: 1px solid ${p => p.theme.colors.grey400};
	outline-offset: -2px;

	&:hover {
		border: 1px solid ${p => p.theme.colors.grey700};
		z-index: 1;
	}

	${p =>
		p.$borderRadius === 'top-left' &&
		css`
			border-radius: 5px 0 0 0;
		`}

	${p =>
		p.$borderRadius === 'top-right' &&
		css`
			border-radius: 0 5px 0 0;
		`}

	${p =>
		p.$borderRadius === 'bottom-left' &&
		css`
			border-radius: 0 0 0 5px;
		`}

	${p =>
		p.$borderRadius === 'bottom-right' &&
		css`
			border-radius: 0 0 5px 0;
		`}

	${p =>
		p.$activeDate &&
		p.$focusedDate &&
		css`
			outline: 2px solid ${p => p.theme.colors.blue600};
			color: ${p => p.theme.colors.blue600};
			z-index: 1;
			&:hover {
				outline: 2px solid ${p => p.theme.colors.blue600};
			}
		`}

	${p =>
		p.disabled &&
		css`
			cursor: not-allowed;
			border-color: ${p => p.theme.colors.grey400};
			color: ${p => p.theme.colors.grey400};
			&:hover {
				border-color: ${p => p.theme.colors.grey400};
				color: ${p => p.theme.colors.grey400};
			}
		`}
`;

const Body = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: 1px;
	padding-left: 1px;
	${Cell} {
		${p =>
			!p.$borders &&
			css`
				border-radius: 5px !important;
				&:not(:hover),
				&:disabled:hover {
					border-color: transparent;
				}
			`}
	}
`;

export default function DateSelector({
	onChange,
	navigate,
	setNavigate,
	disablePrevArrow,
	setDisablePrevArrow,
	disableNextArrow,
	setDisableNextArrow,
	...props
}) {
	const {
		date,
		temporaryDate,
		closeCalendar,
		handleSelectDate,
		setPreviousMonth,
		setPreviousYear,
		setNextMonth,
		setNextYear,
		isSameDate,
		isDateDisabled,
		handleKeyPress,
		setShowCalendar,
	} = useDatePicker();

	/**
	 * Run different function based on key pressed
	 * @param {object} e - The event object
	 * @returns {void}
	 **/
	const handleTableKeyPress = e => {
		const key = e.key;
		switch (key) {
			case 'Escape': // Esc
				closeCalendar();
				break;
			case 'ArrowLeft': // Left
				setPreviousMonth();
				break;
			case 'ArrowRight': // Right
				setNextMonth();
				break;
			default:
				break;
		}
		return;
	};

	/**
	 * Handle date selection
	 * @param {object} current - The current date
	 * @returns {void}
	 **/
	const handleDateSelection = current => {
		handleSelectDate({
			value: dayjs(current),
		});
		if (onChange) onChange(current);
	};

	/**
	 * Generate month
	 * @returns {array} - The month
	 **/
	const generateMonth = () => {
		// Get number of days in the month
		const daysInMonth = dayjs(temporaryDate).daysInMonth();

		// Get the index of the first weekday
		const firstWeekday = dayjs(temporaryDate).startOf('month').day() - 1;
		const startWeekday = firstWeekday === -1 ? 6 : firstWeekday;

		// Get the index of the last weekday
		const lastWeekday = dayjs(temporaryDate).endOf('month').day() - 1;
		const endWeekday = lastWeekday === -1 ? 6 : lastWeekday;

		// Create an array of weeks with an array of their weekdays, with null correctly placed to create offset for the grid
		const gridDays = chunk(
			[
				...Array.from({ length: startWeekday }).fill(null),
				...Array.from({ length: daysInMonth }, (_, i) =>
					dayjs(temporaryDate).set('date', i + 1)
				),
				...Array.from({ length: 6 - endWeekday }).fill(null),
			],
			7
		);

		return gridDays;
	};

	/**
	 * Handle keydown
	 * @param {object} e - The event object
	 * @param {function} day - The day
	 * @param {boolean} disabled - Indicates if the day is disabled
	 * @returns {void}
	 **/
	const handleKeyDown = (e, day, disabled) => {
		e.preventDefault();
		if (disabled) return;
		if (['Space', 'Enter'].includes(e.key)) {
			handleDateSelection(day);
			setShowCalendar(false);
		}
	};

	return (
		<Wrapper
			aria-label="Datovelger. Trykk ESC for å lukke."
			data-cy="datepicker"
			className="datepicker__view datepicker__view--date">
			<Navigation
				buttonText={dayjs(temporaryDate).format('MMMM YYYY')}
				changeViewTo={'month'}
				disableChangeView={
					props?.showNavigations?.length === 1 &&
					props?.showNavigations?.includes('day')
				}
				toggle={!(disableNextArrow && disablePrevArrow)}
				prevDoubleArrow={
					props.doubleArrows && {
						tabIndex: '0',
						ariaLabel: disablePrevArrow
							? 'Kan ikke gå til forrige år'
							: 'Gå til forrige år',
						onClick: setPreviousYear,
						onKeyPress: e => handleKeyPress(e, setPreviousYear),
					}
				}
				nextDoubleArrow={
					props.doubleArrows && {
						tabIndex: '0',
						ariaLabel: disableNextArrow
							? 'Kan ikke gå til neste år'
							: 'Gå til neste år',
						onClick: setNextYear,
						onKeyPress: e => handleKeyPress(e, setNextYear),
					}
				}
				prevArrow={{
					tabIndex: '0',
					ariaLabel: disablePrevArrow
						? 'Kan ikke gå til forrige måned'
						: 'Gå til forrige måned',
					onClick: setPreviousMonth,
					onKeyPress: e => handleKeyPress(e, setPreviousMonth),
				}}
				nextArrow={{
					tabIndex: '0',
					ariaLabel: disableNextArrow
						? 'Kan ikke gå til neste måned'
						: 'Gå til neste måned',
					onClick: setNextMonth,
					onKeyPress: e => handleKeyPress(e, setNextMonth),
				}}
				disableNextArrow={disableNextArrow}
				disablePrevArrow={disablePrevArrow}
			/>

			<Table
				tabIndex="0"
				onKeyDown={handleTableKeyPress}
				role="grid"
				data-cy="calendar-grid"
				aria-label="Månedsoversikt">
				<Head role="row">
					<Heading role="columnheader" aria-label="Mandag">
						<abbr title="Mandag">M</abbr>
					</Heading>
					<Heading role="columnheader" aria-label="Tirsdag">
						<abbr title="Tirsdag">T</abbr>
					</Heading>
					<Heading role="columnheader" aria-label="Onsdag">
						<abbr title="Onsdag">O</abbr>
					</Heading>
					<Heading role="columnheader" aria-label="Torsdag">
						<abbr title="Torsdag">T</abbr>
					</Heading>
					<Heading role="columnheader" aria-label="Fredag">
						<abbr title="Fredag">F</abbr>
					</Heading>
					<Heading role="columnheader" aria-label="Lørdag">
						<abbr title="Lørdag">L</abbr>
					</Heading>
					<Heading role="columnheader" aria-label="Søndag">
						<abbr title="Søndag">S</abbr>
					</Heading>
				</Head>

				<Body
					role="row"
					aria-label="Månedsoversikt"
					$borders={props?.fixedCalendar}>
					{generateMonth().map((week, weekIndex) => {
						return (
							<Fragment key={`week-${weekIndex}`}>
								{week.map((day, i) => {
									const disabled = isDateDisabled({
										date: day,
										...props,
									});

									return (
										<Cell
											key={`day-cell-${i}`}
											type="button"
											role="gridcell"
											aria-label={
												((!day || disabled) &&
													'Ikke mulig å velge') ||
												`Velg denne dagen: ${dayjs(
													day
												).format('D. MMMM')}`
											}
											data-cy={`calendar-grid-date${
												(disabled && '-blocked') || ''
											}`}
											disabled={disabled || !day}
											$activeDate={isSameDate(date, day)}
											$focusedDate={isSameDate(
												temporaryDate,
												day
											)}
											$borderRadius={getBorderRadius(
												i,
												weekIndex,
												week.length
											)}
											onClick={e => {
												e.preventDefault();
												if (disabled) return;

												handleDateSelection(day);
											}}
											onKeyDown={e =>
												handleKeyDown(e, day, disabled)
											}
											aria-selected={isSameDate(
												date,
												day
											)}>
											{day && dayjs(day).get('date')}
										</Cell>
									);
								})}
							</Fragment>
						);
					})}
				</Body>
			</Table>
		</Wrapper>
	);
}

/**
 * Get border radius for each cell
 * @param {number} i - The index of the cell
 * @param {number} weekIndex - The index of the week
 * @param {number} weekLength - The length of the week
 * @returns {string} - The border radius
 */
function getBorderRadius(i, weekIndex, weekLength) {
	if (!i || !weekIndex || !weekLength) return;
	if (weekIndex === 0 && i === 0) return 'top-left';
	if (weekIndex === 0 && i === 6) return 'top-right';
	if (weekIndex === weekLength && i === 0) return 'bottom-left';
	if (weekIndex === weekLength && i === 6) return 'bottom-right';
}
