import React, { forwardRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

import ScreenReaderText from 'components/screen-reader-text';
import FileUploadIcon from 'images/icons/Upload';
import Check from 'images/icons/Check';
import Button from 'components/forms/Button';

const Wrapper = styled.div`
	margin-bottom: 20px;
	border-radius: ${p => p.theme.utils.borderRadius};
	border: 1.5px dashed ${p => p.theme.colors.grey400};
	background-color: ${p => (p.$hasFiles ? 'white' : p.theme.colors.grey200)};
	margin-bottom: 5px;
	padding: 20px 20px 10px;
	${p =>
		p.$hovering &&
		`
		border-color: ${p.theme.colors.grey700};
		background-color: white;
	`}

	&:hover {
		border-color: ${p => p.theme.colors.grey700};
	}
`;
const InputWrapper = styled.div`
	position: relative;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	transition: all 350ms ease;
	margin-bottom: 10px;
	cursor: pointer;
	${p =>
		p.theme.media.XSmallOnly(css`
			flex-direction: column;
			margin-bottom: 0px;
		`)}
`;
const Icon = styled(FileUploadIcon)`
	text-align: center;
	font-size: 20px;
	font-weight: 400;
	line-height: 29px;
	margin-right: 10px;
`;
const InfoText = styled.span`
	font-size: 17px;
	font-weight: 400;
	line-height: 29px;
`;
const StyledButton = styled(Button)`
	margin-left: auto;
	cursor: pointer;
	pointer-events: none;
	${p =>
		p.theme.media.XSmallOnly(css`
			margin-left: 0;
			margin-top: 10px;
		`)}
`;
const StyledHiddenInput = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	cursor: pointer;
	&:focus {
		outline: 0;
	}

	&::file-selector-button {
		display: none;
	}
`;
const HelperText = styled.p`
	margin: 0;
	color: ${p =>
		p.isError ? p.theme.colors.coral900 : p.theme.colors.grey700};
	font-weight: ${p => (p.isError ? '500' : '400')};
	font-size: 14px;
	line-height: 22px;
`;
const Files = styled.ul`
	flex: 0 0 100%;
	margin: -10px 0 0 0;
	padding: 0;
	list-style: none;
`;
const File = styled.li`
	display: flex;
	font-size: 16px;
	border-bottom: 1px solid ${p => p.theme.colors.grey300};
	padding: 10px 0;

	&:last-of-type {
		border: none;
	}
`;
const StatusIcon = styled.div`
	flex: 0 0 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	height: 29px;
	font-size: 20px;
	color: ${p =>
		p.error ? p.theme.colors.coral700 : p.theme.colors.green500};
`;
const FileName = styled.span`
	font-size: 17px;
	line-height: 29px;
`;
const RemoveImageButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	color: ${p => p.theme.colors.blue600600};
	text-align: center;
	font-size: 20px;
	line-height: 29px;
	width: 26px;
	height: 29px;
	background: transparent;
	border: 0;
`;

const UploadField = forwardRef(
	(
		{
			onChange = () => {},
			value = [],
			limit = 3,
			maxSize,
			helperText,
			...props
		},
		ref
	) => {
		const [imageError, setImageError] = useState('');
		const [hover, setHover] = useState(false);
		const [files, setFiles] = useState([]);

		useEffect(() => {
			const updatedFiles = validateImages(files);

			onChange(updatedFiles);
			//eslint-disable-next-line
		}, [files.length]);

		function validateImages(items) {
			if (!items) return false;

			let isValid = true;
			for (let i = 0; i < items.length; i++) {
				if (items[i].size === 0) {
					items[i].error =
						'Filen er 0 bytes, den er kanskje korrupt.';
					isValid = false;
				}

				if (maxSize && items[i].size > maxSize) {
					items[i].error = 'Filen er for stor. Max. 10MB';
					isValid = false;
				}

				const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
				if (validTypes.indexOf(items[i].type) === -1) {
					items[i].error =
						'Bildet har feil filformat, vi aksepterer kun JPG, JPEG eller PNG.';
					isValid = false;
				}
			}

			setImageError(!isValid ? 'Det er noe feil med bildene' : '');

			return items;
		}

		return (
			<div style={props?.style}>
				<Wrapper
					ref={ref}
					$hovering={hover}
					$hasFiles={files?.length > 0}>
					<InputWrapper
						onDragEnter={() => setHover(true)}
						onDragLeave={() => setHover(false)}
						onDrop={e => {
							e.preventDefault();
							setHover(false);

							const items = [
								...files,
								...Array.from(e.dataTransfer.files),
							];

							if (items.length > limit) {
								setImageError(
									`Ikke last opp mer enn ${limit} ${
										limit === 1 ? 'fil' : 'filer'
									}.`
								);
								return false;
							}

							setFiles(items);
						}}>
						<Icon size="xl" width="25" height="20" />

						<InfoText>
							Dra og slipp bilder her (maks {limit}).
						</InfoText>

						<StyledHiddenInput
							type="file"
							tabIndex="0"
							data-cy="order-form-images"
							onChange={e => {
								const items = [
									...files,
									...Array.from(e.target.files),
								];

								if (items.length > limit) {
									setImageError(
										`Ikke last opp mer enn ${limit} ${
											limit === 1 ? 'fil' : 'filer'
										}.`
									);
									return false;
								}

								setFiles(items);
							}}
							accept="image/png, image/jpg, image/jpeg"
							multiple
							{...props}
						/>

						<StyledButton type="button" outlined small>
							Last inn filer
						</StyledButton>
					</InputWrapper>

					{files?.length > 0 && (
						<Files>
							{files?.map((file, i) => {
								return (
									<File key={i}>
										<StatusIcon error={!!file.error}>
											{file.error ? (
												<FontAwesomeIcon
													icon={faCircleExclamation}
												/>
											) : (
												<Check />
											)}
										</StatusIcon>

										<div>
											<FileName>{file.name}</FileName>

											{file.error && (
												<HelperText>
													{file.error}
												</HelperText>
											)}
										</div>

										<RemoveImageButton
											aria-label="Fjern filen"
											type="button"
											onClick={() => {
												const updatedFiles =
													files.filter(
														(_, index) =>
															index !== i
													);
												if (updatedFiles <= limit) {
													setImageError('');
												}
												setFiles(updatedFiles);
											}}>
											<ScreenReaderText>
												Klikk for å fjerne bildet
											</ScreenReaderText>

											<FontAwesomeIcon icon={faXmark} />
										</RemoveImageButton>
									</File>
								);
							})}
						</Files>
					)}
				</Wrapper>

				{helperText && <HelperText>{helperText}</HelperText>}

				{imageError && (
					<HelperText isError={true}>{imageError}</HelperText>
				)}
			</div>
		);
	}
);

export default UploadField;
