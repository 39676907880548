import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useDatePicker } from './Provider';
import Navigation from './Navigation';

/* Init dayjs plugin */
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 20px 0;
`;

const Cell = styled.button`
	text-align: center;
	font-weight: 400;
	font-size: 17px;
	height: 49px;
	background: transparent;
	width: calc(100% / 4 + 1px);
	margin-top: -1px;
	margin-left: -1px;
	border: 1px solid ${p => p.theme.colors.grey400};
	outline-offset: -2px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	&:hover {
		border: 1px solid ${p => p.theme.colors.grey700};
		z-index: 1;
	}

	${p =>
		p.$activeMonth &&
		css`
			outline: 2px solid ${p => p.theme.colors.blue600};
			color: ${p => p.theme.colors.blue600};
			z-index: 1;
			&:hover {
				outline: 2px solid ${p => p.theme.colors.blue600};
			}
		`}

	${p =>
		p.disabled &&
		css`
			cursor: not-allowed;
			border-color: ${p => p.theme.colors.grey400};
			color: ${p => p.theme.colors.grey400};
			&:hover {
				border-color: ${p => p.theme.colors.grey400};
				color: ${p => p.theme.colors.grey400};
			}
		`}
`;

const Body = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: 1px;
	padding-left: 1px;
	margin: 20px 0 0;

	${Cell} {
		&:first-of-type {
			border-radius: 5px 0 0 0;
		}
		&:nth-child(4) {
			border-radius: 0 5px 0 0;
		}
		&:nth-child(9) {
			border-radius: 0 0 0 5px;
		}
		&:last-of-type {
			border-radius: 0 0 5px 0;
		}
		${p =>
			!p.$borders &&
			css`
				border-radius: 5px !important;
				&:not(:hover),
				&:disabled:hover {
					border-color: transparent;
				}
			`}
	}
`;

export default function MonthSelector({
	onChange,
	navigate,
	setNavigate,
	disablePrevArrow,
	setDisablePrevArrow,
	disableNextArrow,
	setDisableNextArrow,
	...props
}) {
	const {
		date,
		temporaryDate,
		setTemporaryDate,
		setCurrentView,
		setPreviousYear,
		setNextYear,
		handleKeyPress,
		isDateDisabled,
	} = useDatePicker();

	const [month, setMonth] = useState(
		(date && dayjs(date).month()) ||
			(temporaryDate && dayjs(temporaryDate).month()) ||
			new Date().getMonth()
	);

	return (
		<Wrapper
			aria-label="Månedsvelger. Trykk ESC for å lukke."
			className="datepicker__view datepicker__view--month">
			<Navigation
				buttonText={dayjs(temporaryDate).format('YYYY')}
				changeViewTo="year"
				toggle={!(disableNextArrow && disablePrevArrow)}
				prevDoubleArrow={{
					tabIndex: '0',
					ariaLabel: disablePrevArrow
						? 'Kan ikke gå til forrige år'
						: 'Gå til forrige år',
					onClick: setPreviousYear,
					onKeyPress: e => handleKeyPress(e, setPreviousYear),
				}}
				nextDoubleArrow={{
					tabIndex: '0',
					ariaLabel: disableNextArrow
						? 'Kan ikke gå til neste år'
						: 'Gå til neste år',
					onClick: setNextYear,
					onKeyPress: e => handleKeyPress(e, setNextYear),
				}}
				disableNextArrow={disableNextArrow}
				disablePrevArrow={disablePrevArrow}
			/>

			<Body
				tabIndex="0"
				aria-label="Årsoversikt"
				$borders={props?.fixedCalendar}>
				{!!dayjs.months().length &&
					dayjs.months().map((monthName, i) => {
						// Get the year from the temporaryDate-state / today's date

						const year =
							(temporaryDate &&
								dayjs(temporaryDate).format('YYYY')) ||
							dayjs().format('YYYY');

						// Get the first date of the current month and year
						const firstOfMonthAndYear = dayjs()
							.set('month', i)
							.set('year', year);

						if (!firstOfMonthAndYear) return <></>;

						const disabled = isDateDisabled({
							date: firstOfMonthAndYear,
							type: 'month',
							...props,
						});

						return (
							<Cell
								key={`${monthName}`}
								type="button"
								$activeMonth={!disabled && month === i}
								disabled={disabled}
								tabIndex="0"
								onClick={e => {
									e.preventDefault();
									if (disabled) return;

									setMonth(i);
									setTemporaryDate(
										temporaryDate.month(i).date(1)
									);
									setCurrentView('day');
								}}
								aria-label="Velg denne måneden"
								aria-selected={!disabled && month === i}
								data-cy="calendar-grid-month"
								onKeyDown={e => {
									e.preventDefault();
									if (disabled) return;

									if (['Space', 'Enter'].includes(e.key)) {
										setMonth(i);
										setTemporaryDate(
											temporaryDate.month(i).date(1)
										);
										setCurrentView('day');
									}
								}}>
								{monthName}
							</Cell>
						);
					})}
			</Body>
		</Wrapper>
	);
}
