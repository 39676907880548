import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { useDatePicker } from './Provider';
import DateSelector from './DateSelector';
import MonthSelector from './MonthSelector';
import YearSelector from './YearSelector';
import Actions from './Actions';

/* Init dayjs plugin */
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const Wrapper = styled.div`
	${p =>
		p.$absolute &&
		css`
			position: absolute;
			top: 100%;
			right: 0px;
			width: 387px;
			max-width: 100%;
			background: white;
			box-shadow: ${p => p.theme.utils.boxShadow};
			border-radius: ${p => p.theme.utils.borderRadius};
			z-index: 3;
			padding: 20px 20px 20px;
		`}
`;

export default function Calendar({ showNavigations = [], onChange, ...props }) {
	const [disablePrevArrow, setDisablePrevArrow] = useState(false);
	const [disableNextArrow, setDisableNextArrow] = useState(false);
	const [navigate, setNavigate] = useState(0);

	/* Init norwegian locale */
	dayjs.locale('nb');

	const {
		date,
		temporaryDate,
		setTemporaryDate,
		currentView,
		setPreviousYear,
		setNextYear,
		disablePreviousNav,
		disableNextNav,
	} = useDatePicker();

	useEffect(() => {
		// If min-date set then update as temporary-date
		if (props?.minDate && !date) {
			setTemporaryDate(props.minDate);
		}

		// If max-date set then update as temporary-date
		if (props?.maxDate && !date) {
			setTemporaryDate(dayjs());
		}

		// If specific available dates set then update state to the first available
		if (props?.availableDates?.length > 0 && !date) {
			setTemporaryDate(dayjs(props.availableDates[0]));
		}

		//eslint-disable-next-line
	}, []);

	// Update navigation arrows
	useEffect(() => {
		setDisablePrevArrow(disablePreviousNav({ type: 'day', ...props }));
		setDisableNextArrow(disableNextNav({ type: 'day', ...props }));
		//eslint-disable-next-line
	}, [
		temporaryDate,
		currentView,
		navigate,
		setPreviousYear,
		setNextYear,
		props?.availableDates?.length,
	]);

	// Define props for selectors
	const selectorProps = {
		onChange,
		navigate,
		setNavigate,
		disablePrevArrow,
		setDisablePrevArrow,
		disableNextArrow,
		setDisableNextArrow,
		...props,
	};

	if (!showNavigations?.length > 0) return null;

	return (
		<Wrapper $absolute={!props?.fixedCalendar}>
			{(currentView === 'year' && showNavigations?.includes('year') && (
				<YearSelector {...selectorProps} />
			)) ||
				(currentView === 'month' &&
					showNavigations.includes('month') && (
						<MonthSelector {...selectorProps} />
					)) ||
				(currentView === 'day' && showNavigations.includes('day') && (
					<DateSelector {...selectorProps} />
				))}

			{!props?.fixedCalendar && <Actions {...props} />}
		</Wrapper>
	);
}
