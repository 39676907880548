import React, { forwardRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import { format as formatFn, parse, isValid } from 'date-fns';
import { nb } from 'date-fns/locale/nb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons/faCircleNotch';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays';

import {
	Wrapper,
	IconWrap,
	IconLoading,
	LoadingWrap,
	ClearWrap,
	Error,
	HelpText,
} from './InputField';
import 'react-datepicker/dist/react-datepicker.css';

const StyledWrapper = styled(Wrapper)`
	input {
		width: 100%;
	}
`;

/**
 * DatePicker component using Date-fns
 * @param {string} label - Label for the input field
 * @param {string} placeholder - Placeholder for the input field
 * @param {object} error - Error object
 * @param {boolean} clear - Clear button
 * @param {array} format - Date formats
 * @param {function} onChange - On change function
 * @param {boolean} loading - Loading state
 * @param {string} helpText - Help text
 * @param {object} icon - Icon
 * @param {boolean} leadingIcon - Leading icon
 * @param {string} className - Additional classes
 * @param {object} props - Additional props
 * @returns {JSX.Element}
 */
const DatePicker = forwardRef(
	(
		{
			label,
			placeholder,
			error,
			clear,
			format = ['dd/MM/yyyy', 'dd-MM-yyyy', 'ddMMyyyy'], // Must use date-fns format
			onChange,
			loading = false,
			helpText,
			defaultValue,
			value,
			icon = faCalendarDays,
			leadingIcon = false,
			className,
			...props
		},
		ref
	) => {
		const [isActive, setIsActive] = useState(!!value || !!defaultValue);
		const [date, setDate] = useState();

		useEffect(() => {
			if (date) return;
			if (!!value || !!defaultValue) {
				setIsActive(true);

				const formats = Array.isArray(format) ? format : [format];
				let parsedDate;

				for (let fmt of formats) {
					parsedDate = parse(value || defaultValue, fmt, new Date(), {
						locale: nb,
					});
					if (isValid(parsedDate)) break;
				}

				if (isValid(parsedDate)) {
					setDate(parsedDate);
				}
			}

			//eslint-disable-next-line
		}, [value, defaultValue, format]);

		let classes = isActive ? 'active' : '';
		if (error) {
			classes += ' error';
		}
		if (helpText) {
			classes += ' help-text';
		}
		if (icon) {
			classes += ' icon';
		}
		if (leadingIcon) {
			classes += ' leading-icon';
		}
		if (loading) {
			classes += ' loading';
		}
		if (className) {
			classes += ` ${className}`;
		}

		const text = label || placeholder;

		const Icon = icon?.prefix ? FontAwesomeIcon : icon;

		return (
			<StyledWrapper className={classes}>
				<ReactDatePicker
					dateFormat={format}
					onFocus={e => setIsActive(!!e.target.value)}
					onBlur={e => setIsActive(!!e.target.value)}
					onChange={d => {
						const formats = Array.isArray(format)
							? format
							: [format];
						let formattedDate;

						for (let fmt of formats) {
							formattedDate = formatFn(d, fmt, {
								locale: nb,
							});
							if (formattedDate) break;
						}

						onChange && onChange(formattedDate);
						setDate(d);
					}}
					selected={date}
					id={props.id || props.name}
					ref={ref}
					locale={nb}
					{...props}
				/>

				{text && (
					<label htmlFor={props.id || props.name}>
						{text}

						{props.required && (
							<span className="required-indicator">*</span>
						)}
					</label>
				)}

				{!error && icon && (
					<IconWrap className="icon-wrapper">
						{icon?.prefix ? <Icon icon={icon} /> : <Icon />}
					</IconWrap>
				)}

				{(loading && (
					<LoadingWrap className="loading-wrapper">
						<IconLoading
							icon={faCircleNotch}
							className="fa-spin"
							width="20"
							height="20"
						/>
					</LoadingWrap>
				)) ||
					(clear && (
						<ClearWrap className="clear-wrapper">{clear}</ClearWrap>
					))}

				{error && (
					<>
						<IconWrap>
							<FontAwesomeIcon icon={faCircleExclamation} />
						</IconWrap>

						<Error>{error?.message || error}</Error>
					</>
				)}

				{!error && helpText && <HelpText>{helpText}</HelpText>}
			</StyledWrapper>
		);
	}
);

export default DatePicker;

/**
 * Validate date string
 * @param {string} value - The date string to validate
 * @param {string} finalFormat - The final date format
 * @returns {object} - The date object
 */
export function validateDateString(value = '', finalFormat = 'dd/MM/yyyy') {
	if (!value || !finalFormat) return;

	const validFormats = [
		'ddMMyyyy',
		'dd.MM.yyyy',
		'dd/MM/yyyy',
		'dd-MM-yyyy',
		'ddMMyyyy',
		'd. MMMM yyyy',
	];

	const valid = validFormats.find(formatStr => {
		const parsedDate = parse(value, formatStr, new Date(), {
			locale: nb,
		});

		return isValid(parsedDate);
	});

	return valid ? value : false;
}
