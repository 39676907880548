import React from 'react';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';

const Highlighter = styled.span`
	position: absolute;
	z-index: 1;
	top: 5px;
	left: 5px;
	height: calc(100% - 10px);
	border-radius: ${p => p.theme.utils.borderRadius};
	transition: transform 0.25s ease-in-out;
	background-color: ${p => p.theme.colors.white};
	box-shadow: 0px 2px 6px rgba(58, 58, 58, 0.15);
	width: ${p =>
		(p.$count > 2 && `calc((100% / ${p.$count}) - 5px)`) ||
		`calc((100% / ${p.$count}) - 5px)`};
	transform: ${p =>
		p.$selectedIndex === 0
			? `translateX(calc(${p.$selectedIndex} * 100%))`
			: p.$count > 2
			? `translateX(calc(${p.$selectedIndex} * 100% + 5px))`
			: `translateX(calc(${p.$selectedIndex} * 100%))`};

	${p =>
		p.theme.media.smallOnly(css`
			${p =>
				p.$count === 4 &&
				css`
					width: calc(100% - 10px) !important;
					height: 54px !important;
					transform: ${p =>
						`translateY(calc(${p.$selectedIndex} * 100% + 5px))`} !important;
				`};
		`)}
`;

const Inner = styled.div`
	position: relative;
	background: ${p => p.theme.colors.blue200};
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: ${p => p.theme.spacing.mobile.xxsmall};
	display: grid;
	gap: 5px;
	grid-template-columns: ${p =>
		(p.$count && `repeat(${p.$count}, 1fr)`) || 'repeat(3, 1fr)'};

	${p =>
		p.theme.media.smallOnly(css`
			${p =>
				p.$count > 2 &&
				!p.$count > 3 &&
				css`
					display: block !important;
					width: 100% !important;
					transform: none !important;
				`};

			${p =>
				p.$count === 4 &&
				css`
					grid-template-columns: repeat(2, 1fr);
					${Highlighter} {
						width: calc(50% - 5px) !important;
						height: 54px !important;
						transform: ${p =>
							p.$selectedIndex === 1
								? 'translateX(100%)'
								: p.$selectedIndex === 2
								? 'translateX(0%) translateY(calc(100% + 5px))'
								: p.$selectedIndex === 3
								? 'translateX(100%) translateY(calc(100% + 5px))'
								: 'translateX(0%)'} !important;
					}
				`};
		`)}

	${p =>
		p.tabletcolumns === 2 &&
		css`
			${p =>
				p.theme.media.tabletOnly(css`
					grid-template-columns: repeat(2, 1fr);
					${Highlighter} {
						width: calc(50% - 5px);
						height: 59px !important;
						transform: ${p =>
							p.$selectedIndex === 1
								? 'translateX(100%)'
								: p.$selectedIndex === 2
								? 'translateX(0%) translateY(calc(100% + 5px))'
								: p.$selectedIndex === 3
								? 'translateX(100%) translateY(calc(100% + 5px))'
								: 'translateX(0%)'} !important;
					}
				`)}
		`}
`;

const Tab = styled.button`
	width: ${p => `calc(100% - ${p.theme.spacing.mobile.xxsmall})`};
	margin: ${p => `0 ${p.theme.spacing.mobile.xxsmall}`};
	min-width: 236px;
	border: 0 !important;
	font-size: 17px;
	line-height: 24px;
	width: 100%;
	height: 100%;
	font-weight: 500;
	padding: ${p => `17.5px ${p.theme.spacing.tablet.small}`};
	z-index: 1;
	text-align: center;
	cursor: pointer;
	border-radius: ${p => p.theme.utils.borderRadius};
	transition: all 0.25s ease-in-out;
	color: ${p => p.theme.colors.blue600};
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	z-index: 2;
	${p =>
		p.theme.media.large(css`
			width: calc(100% - 5px);
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			width: 100%;
			min-width: auto;
			padding: ${p => `${p.theme.spacing.tablet.xsmall} 0`};
			${p =>
				p.$count > 2 &&
				css`
					width: 100%;
					margin-right: 0 !important;
					margin-left: 0 !important;
				`}
		`)}

	

	&:focus {
		outline: 0 !important;
	}
	svg {
		color: ${p => p.theme.colors.green500};
		margin-right: ${p => p.theme.spacing.desktop.xxsmall};
		${p =>
			p.theme.media.smallOnly(css`
				margin-right: ${p => p.theme.spacing.mobile.xxsmall};
			`)}
		${p =>
			p.theme.media.tabletOnly(css`
				margin-right: ${p => p.theme.spacing.mobile.xxsmall};
			`)}
	}
	${p =>
		p.$active &&
		css`
			color: ${p => p.theme.colors.black};
		`}
	${p =>
		!p.$active &&
		css`
			&:hover,
			&:focus {
				background-color: ${p => p.theme.colors.blue300};
			}
		`}
	> span {
		pointer-events: none;
	}
`;

/**
 * A tab switch component.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.tabs - An array of tab objects. Each object should have an `id` and `text` property.
 * @param {string} props.activeTab - The id of the active tab.
 * @param {Function} props.setActiveTab - A callback function that is called when the active tab is changed.
 * @param {Function} props.onSwitch - A callback function that is called when the active tab is switched.
 * @param {boolean} props.fullwidth - A flag to determine whether the switch should be full width or not.
 * @param {Object} props - Rest of the properties passed to the component.
 *
 * @returns {JSX.Element} The rendered tab switch component.
 */
export default function TabSwitch({
	tabs = [],
	activeTab = '',
	setActiveTab = () => {},
	onSwitch = () => {},
	fullwidth = false,
	...props
}) {
	// Get the index of the active tab
	const selectedIndex = tabs.findIndex(tab => tab?.id === activeTab);

	/**
	 * Handles the click event on the switch.
	 * Sets the active tab to the id of the target of the event.
	 * If a callback function `onSwitch` is provided, it is called with the event as argument.
	 *
	 * @param {Event} e - The event object.
	 */
	const handleSwitchClick = debounce(e => {
		setActiveTab(e?.target?.id);
		if (onSwitch) {
			onSwitch(e);
		}
	}, 300);

	/**
	 * Handles the key down event on the tab switch.
	 * If the 'Enter' key is pressed, it triggers the `handleSwitchClick` function.
	 * If the 'ArrowRight', 'ArrowLeft', 'Home', or 'End' keys are pressed, it changes the active tab accordingly.
	 * Prevents the default action for these keys to avoid unwanted side effects.
	 *
	 * @param {KeyboardEvent} event - The event object.
	 */
	const onKeyDown = event => {
		if (event?.code === 'Enter') {
			handleSwitchClick(event);
		}

		const count = tabs?.length;
		const nextTab = () =>
			setActiveTab(tabs[(selectedIndex + 1) % count].id);
		const prevTab = () =>
			setActiveTab(tabs[(selectedIndex - 1 + count) % count].id);
		const firstTab = () => setActiveTab(tabs[0].id);
		const lastTab = () => setActiveTab(tabs[count - 1].id);

		const keyMap = {
			ArrowRight: nextTab,
			ArrowLeft: prevTab,
			Home: firstTab,
			End: lastTab,
		};

		const action = keyMap[event.key];

		if (action) {
			event.preventDefault();
			action();
		}
	};

	// If there are less than 2 tabs, don't render the switch
	if (tabs?.length < 2) return null;

	return (
		<div role="tablist" aria-orientation="horizontal">
			<Inner
				{...props}
				$count={tabs?.length}
				$selectedIndex={selectedIndex}>
				{tabs.map(tab => (
					<Tab
						role="tab"
						key={tab.id}
						id={tab.id}
						aria-selected={activeTab === tab.id}
						aria-label={tab?.ariaLabel || tab?.text}
						onKeyDown={e => onKeyDown(e)}
						onClick={e => handleSwitchClick(e)}
						$active={activeTab === tab.id}
						$count={tabs?.length}
						$fullwidth={fullwidth}>
						{activeTab === tab.id && (
							<FontAwesomeIcon icon={faCheck} />
						)}
						<span>{tab.text}</span>
					</Tab>
				))}

				<Highlighter
					$count={tabs?.length}
					$selectedIndex={selectedIndex}
				/>
			</Inner>
		</div>
	);
}
