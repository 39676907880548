import React, { useState, forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';

import Button from './Button';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 0;
`;

const ToggleButton = styled(Button)`
	padding: 10px 10px;
	width: 100px;
	text-align: center;
	justify-content: center;
`;

const Choices = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	min-width: 260px;
	z-index: 3;
	background: ${p => p.theme.colors.white};
	box-shadow: ${p => p.theme.utils.boxShadow};
	border-radius: 5px;
	padding: 15px 0;
	max-height: 270px;
	overflow: auto;
	p {
		padding: 10px 20px;
		margin: 0;
	}
`;

const Choice = styled.button`
	padding: 10px 20px;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	display: block;
	width: 100%;
	text-align: left;
	margin: 0 !important;
	color: ${p =>
		(p.$isChosen && p.theme.colors.blue600) || p.theme.colors.black};
	&:hover {
		background: ${p => p.theme.colors.blue200};
	}
`;

const Error = styled.div`
	color: ${p => p.theme.colors.coral900};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
	position: absolute;
	bottom: -30px;
	width: 100%;
	min-width: max-content;
	text-align: right;
`;

const IconWrap = styled.span`
	height: 17px;
	width: 17px;
	font-size: 17px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	text-align: center;
`;

/**
 * Quantity select component
 * @param {object} props
 * @param {number} [props.min=1] - The minimum quantity
 * @param {number} [props.max=5] - The maximum quantity
 * @param {function} [props.onSelect] - The function to run when selecting a quantity
 * @param {number} [props.quantity] - The current quantity
 * @param {function} [props.setQuantity] - The function to set the quantity
 * @param {object} [props.error] - The error object
 * @param {object} [props.ref] - The ref object
 * @param {object} [props.props] - The props object
 * @returns {JSX.Element}
 **/
const QtySelect = forwardRef(
	(
		{ min = 1, max = 5, onSelect, quantity, setQuantity, error, ...props },
		ref
	) => {
		const [isOpen, setIsOpen] = useState(false);
		const wrapRef = useRef(null);

		// Define classes based on error, active, help-text and icon
		let classes = isOpen ? 'qty-select active' : 'qty-select';
		if (error) {
			classes += ' error';
		}

		// Close dropdown if clicking outside when open
		function handleClickOutside(event) {
			if (!isOpen) return;
			if (wrapRef?.current && !wrapRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		}

		// Close dropdown if pressing escape when open
		function handleKeyDown(event) {
			if (!isOpen) return;
			if (event.key === 'Escape') {
				setIsOpen(false);
				if (ref?.current) ref.current.blur();
			}
		}

		// If the dropdown-list is open and input-ref exists then set focus to the input-ref
		useEffect(() => {
			if (isOpen && ref?.current) ref?.current?.focus();

			document.addEventListener('click', handleClickOutside, true);
			document.addEventListener('keydown', handleKeyDown, true);
			return () => {
				document.removeEventListener('click', handleClickOutside, true);
				document.removeEventListener('keydown', handleKeyDown, true);
			};
			//eslint-disable-next-line
		}, [isOpen, ref?.current?.wrapRef?.current]);

		return (
			<Wrapper
				className={classes}
				ref={wrapRef}
				{...props}
				data-cy="quantity-select">
				<ToggleButton
					outlined="true"
					small={true}
					data-cy="quantity-select-toggle"
					title={
						(quantity === 0 && 'Legg til') ||
						quantity?.toString() + ' stk'
					}
					onClick={e => {
						e.preventDefault();
						setIsOpen(!isOpen);
					}}
					icon={
						(isOpen && quantity !== 0 && faCaretUp) ||
						(quantity !== 0 && faCaretDown) ||
						''
					}>
					{(quantity === 0 && 'Legg til') ||
						quantity?.toString() + ' stk'}
				</ToggleButton>

				{isOpen && (
					<Choices
						id="qty-list"
						tabIndex="-1"
						role="listbox"
						data-cy="quantity-select-list">
						{min !== 1 && (
							<Choice
								key={0}
								role="option"
								onClick={() => {
									// If onSelect is defined, run it
									if (onSelect) onSelect(0);

									// Clear quantity
									setQuantity(0);

									// Close the dropdown-list
									setIsOpen(!isOpen);
								}}>
								Ingen
							</Choice>
						)}

						{Array.from({ length: max - min + 1 }, (_, i) => {
							const option = i + min;

							return (
								<Choice
									key={option}
									role="option"
									title={`Velg ${option} stk`}
									$isChosen={quantity === option}
									onClick={() => {
										// If already this current quantity don't proceed, close the dropdown-list
										if (quantity === option) {
											setIsOpen(!isOpen);
											return;
										}

										if (onSelect) onSelect(option);

										// Save to state (overwrite if is not multi, and push if is multi)
										setQuantity(option);

										// Close the dropdown-list
										setIsOpen(!isOpen);
									}}>
									{option} stk
								</Choice>
							);
						})}
					</Choices>
				)}

				{error && (
					<Error>
						<IconWrap>
							<FontAwesomeIcon icon={faCircleExclamation} />
						</IconWrap>
						<span>{error?.message || error}</span>
					</Error>
				)}
			</Wrapper>
		);
	}
);

export default QtySelect;
