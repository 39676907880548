import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fadeIn } from 'libs/animations';
import { getFontAwesomeIcon } from './Button';
import MaxWidth from 'layouts/max-width';

//#region
const Inner = styled.div`
	${p =>
		p.theme.media.medium(css`
			display: flex;
			align-items: center;
			flex-direction: row;
		`)}
`;

const Wrap = styled.div`
	width: 100%;
	padding: 13.5px 20px;
	animation-name: ${fadeIn};
	animation-duration: 500ms;
	animation-fill-mode: forwards;
	opacity: 0;
	background: ${p => p.theme.colors.blue100};
	border: 1px solid ${p => p.theme.colors.blue300};
	border-radius: ${p => p.theme.utils.borderRadius};
	color: ${p => p.theme.colors.black};
	${p =>
		p.theme.media.mediumDown(css`
			padding: 10px 20px;
		`)}

	${p =>
		p.mode === 'dark' &&
		css`
			background: ${p.theme.colors.blue700};
			border: 1px solid ${p.theme.colors.blue900};
			color: ${p.theme.colors.white};
		`}

	${p =>
		p.width === 'full' &&
		css`
			padding: 13.5px 0;
			border-radius: 0;
			${p =>
				p.theme.media.mediumDown(css`
					padding: 10px 0;
				`)}
			${Inner} {
				display: flex;
				align-items: center;
				flex-direction: row;
			}
			.action {
				margin-top: 0;
			}
		`}

	${p =>
		p.width === 'large' &&
		css`
			border-radius: ${p => p.theme.utils.borderRadius};
			.max-width {
				max-width: 100%;
				padding: 0;
			}
		`}
`;

const Content = styled.div`
	flex-grow: 1;
	font-size: 17px;
	line-height: 27px;
	padding-right: 10px;
	${p =>
		p.theme.media.medium(css`
			display: flex !important;
			align-items: center;
			padding-right: 20px;
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 15px;
			line-height: 22px;
			padding-right: 5px;
		`)}
	${p =>
		p.theme.media.medium(css`
			> div {
				padding-right: 10px;
			}
		`)}
	a {
		pointer-events: all;
	}
`;

const Icon = styled.span`
	margin-right: 10px;
	text-align: left;
	display: inline-block;
	${p =>
		p.theme.media.smallOnly(css`
			float: left;
		`)}
	svg {
		width: 17px;
		height: 26px;
		text-align: left;
		display: block;
		fill: ${p => p.theme.colors.coral800};
		${p =>
			p.theme.media.smallOnly(css`
				height: 21px;
			`)}
	}
`;

const Action = styled.div`
	text-align: right;
	display: inline-block;
	margin-left: auto;
	min-width: fit-content;
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: 10px;
		`)}
`;

//#endregion

export default function CallToAction({
	text,
	width = 'full',
	mode = 'light',
	icon,
	button,
	style,
}) {
	if (!text) return null;

	return (
		<Wrap style={style} width={width} mode={mode}>
			<MaxWidth className="max-width">
				<Inner>
					<Content>
						{icon && (
							<Icon>
								{icon?.prefix
									? () => <FontAwesomeIcon icon={icon} />
									: typeof icon === 'string' && icon
									? () => (
											<FontAwesomeIcon
												icon={getFontAwesomeIcon(icon)}
											/>
									  )
									: icon}
							</Icon>
						)}

						{text}
					</Content>
					{button && <Action className="action">{button}</Action>}
				</Inner>
			</MaxWidth>
		</Wrap>
	);
}
