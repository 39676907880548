import React from 'react';
import styled from 'styled-components';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';

import { useDatePicker } from './Provider';
import Button from 'components/forms/Button';

const ActionsWrap = styled.div`
	display: flex;
	justify-content: flex-end;

	button {
		margin-left: 20px;
	}
`;

/**
 * Represents a component for the actions in the date picker.
 * @returns {ReactNode} - A React element representing the Actions component.
 **/
export default function Actions({ ...props }) {
	const { date, setShowCalendar } = useDatePicker();

	return (
		<ActionsWrap>
			<Button
				outlined
				small
				onClick={e => {
					e.preventDefault();
					if (props?.fixedCalendar) return;
					setShowCalendar(false);
				}}>
				Avbryt
			</Button>

			<Button
				small
				disabled={
					!date ||
					props?.invalidDate === 'Ikke mulig å velge denne datoen'
				}
				icon={faCheck}
				onClick={e => {
					e.preventDefault();
					if (props?.fixedCalendar) return;
					setShowCalendar(false);
				}}>
				OK
			</Button>
		</ActionsWrap>
	);
}
