import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { useDatePicker } from './Provider';
import Navigation from './Navigation';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 20px 0;
`;

const Cell = styled.button`
	text-align: center;
	font-weight: 400;
	font-size: 17px;
	height: 49px;
	background: transparent;
	width: calc(100% / 4 + 1px);
	margin-top: -1px;
	margin-left: -1px;
	border: 1px solid ${p => p.theme.colors.grey400};
	outline-offset: -2px;
	&:hover {
		border: 1px solid ${p => p.theme.colors.grey700};
		z-index: 1;
	}

	${p =>
		p.$activeYear &&
		css`
			outline: 2px solid ${p => p.theme.colors.blue600};
			color: ${p => p.theme.colors.blue600};
			z-index: 1;
			&:hover {
				outline: 2px solid ${p => p.theme.colors.blue600};
			}
		`}

	${p =>
		p.disabled &&
		css`
			cursor: not-allowed;
			border-color: ${p => p.theme.colors.grey400};
			color: ${p => p.theme.colors.grey400};
			&:hover {
				border-color: ${p => p.theme.colors.grey400};
				color: ${p => p.theme.colors.grey400};
			}
		`}
`;

const Body = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 20px 0 0;
	padding-top: 1px;
	padding-left: 1px;
	${Cell} {
		&:first-of-type {
			border-radius: 5px 0 0 0;
		}
		&:nth-child(4) {
			border-radius: 0 5px 0 0;
		}
		&:nth-child(9) {
			border-radius: 0 0 0 5px;
		}
		&:last-of-type {
			border-radius: 0 0 5px 0;
		}
		${p =>
			!p.borders &&
			css`
				border-radius: 5px !important;
				&:not(:hover),
				&:disabled:hover {
					border-color: transparent;
				}
			`}
	}
`;

export default function YearSelector({
	onChange,
	navigate,
	setNavigate,
	disablePrevArrow,
	setDisablePrevArrow,
	disableNextArrow,
	setDisableNextArrow,
	...props
}) {
	const {
		date,
		temporaryDate,
		setTemporaryDate,
		setCurrentView,
		getThisYear,
		handleKeyPress,
		isDateDisabled,
		dateExistsInYearsArray,
	} = useDatePicker();

	const [year, setYear] = useState(
		date
			? dayjs(date).year()
			: temporaryDate
			? dayjs(temporaryDate).year()
			: new Date().getFullYear()
	);

	const [decade, setDecade] = useState([]);

	function isPrevDecadeDisabled({ currentDecade, ...props }) {
		if (!currentDecade) return false;

		const dateExistsInDecade = dateExistsInYearsArray(
			temporaryDate,
			currentDecade
		);

		// Block all future decades from temporary-dates's year
		if (props?.blockPastDates) {
			if (!dateExistsInDecade) {
				return false;
			} else {
				return true;
			}
		}

		// If has max-date
		if (props?.maxDate) {
			const maxDateExistsInDecade = dateExistsInYearsArray(
				props?.maxDate,
				currentDecade
			);
			if (maxDateExistsInDecade) {
				return false;
			}
		}

		// If has min-date
		if (props?.minDate) {
			const minDateExistsInDecade = dateExistsInYearsArray(
				props?.minDate,
				currentDecade
			);
			if (!minDateExistsInDecade) {
				return false;
			}
		}
	}

	function isNextDecadeDisabled({ currentDecade, ...props }) {
		if (!currentDecade) return false;

		const dateExistsInDecade = dateExistsInYearsArray(
			temporaryDate,
			currentDecade
		);

		// Block all future decades from temporary-dates's year
		if (props?.blockFutureDates) {
			if (!dateExistsInDecade) {
				return false;
			} else {
				return true;
			}
		}

		// If has min-date
		if (props?.minDate) {
			const minDateExistsInDecade = dateExistsInYearsArray(
				props?.minDate,
				currentDecade
			);
			if (minDateExistsInDecade) {
				return true;
			}
		}

		// If has max-date
		if (props?.maxDate) {
			const maxDateExistsInDecade = dateExistsInYearsArray(
				props?.maxDate,
				currentDecade
			);
			if (maxDateExistsInDecade) {
				return true;
			}
		}
	}

	// Array of years / decade
	useEffect(() => {
		// Get the current year + the integer saved in state
		const currentYear = year + navigate;
		const years = [];
		// Push 12 years to array, staring from the current year and increasing by 1 for each
		for (let y = currentYear - 1; y < currentYear + 11; y++) {
			years.push(y);
		}
		// Update state
		setDecade(years);

		// Convert array of integers to strings
		const yearsToString = years.join().split(',');

		// Disable prev arrow based on current decade and calendar settings
		setDisablePrevArrow(
			isPrevDecadeDisabled({
				currentDecade: yearsToString,
				...props,
			})
		);

		// Disable next arrow based on current decade and calendar settings
		setDisableNextArrow(
			isNextDecadeDisabled({
				currentDecade: yearsToString,
				...props,
			})
		);

		//eslint-disable-next-line
	}, [navigate, decade[0]]);

	return (
		<Wrapper
			aria-label="Årsvelger. Trykk ESC for å lukke."
			className="datepicker__view datepicker__view--year">
			<Navigation
				changeViewTo="day"
				buttonText={
					!!decade?.length &&
					`${decade[0]} - ${decade[decade.length - 1]}`
				}
				toggle={!(disableNextArrow && disablePrevArrow)}
				prevDoubleArrow={{
					tabIndex: '0',
					ariaLabel: disablePrevArrow
						? 'Kan ikke gå til forrige dekade'
						: 'Gå til forrige dekade',
					onClick: () => {
						setNavigate(navigate - 12);
					},
					onKeyPress: e =>
						handleKeyPress(e, setNavigate(navigate - 12)),
				}}
				nextDoubleArrow={{
					tabIndex: '0',
					ariaLabel: disableNextArrow
						? 'Kan ikke gå til neste dekade'
						: 'Gå til neste dekade',
					onClick: () => {
						setNavigate(navigate + 12);
					},
					onKeyPress: e =>
						handleKeyPress(e, setNavigate(navigate + 12)),
				}}
				disableNextArrow={disableNextArrow}
				disablePrevArrow={disablePrevArrow}
			/>

			<Body
				tabIndex="0"
				aria-label="Dekade-oversikt"
				borders={props?.fixedCalendar}>
				{!!decade?.length &&
					decade.map((y, i) => {
						const disabled = isDateDisabled({
							date: dayjs().set('year', y),
							type: 'year',
							...props,
						});

						return (
							<Cell
								key={`${i}`}
								type="button"
								disabled={disabled}
								$activeYear={getThisYear(temporaryDate) === y}
								tabIndex="0"
								onClick={e => {
									e.preventDefault();
									if (disabled) return;
									const currentDate = dayjs().date();
									const currentMonth = dayjs().month();
									setYear(y);
									setTemporaryDate(
										temporaryDate
											.year(y)
											.month(currentMonth)
											.date(currentDate)
									);
									setCurrentView('day');
								}}
								data-cy="calendar-grid-year"
								aria-label="Velg dette året"
								aria-selected={getThisYear(temporaryDate) === y}
								onKeyDown={e => {
									e.preventDefault();
									if (disabled) return;

									if (['Space', 'Enter'].includes(e.key)) {
										const currentDate = dayjs().date();
										const currentMonth = dayjs().month();
										setYear(y);
										setTemporaryDate(
											temporaryDate
												.year(y)
												.month(currentMonth)
												.date(currentDate)
										);
										setCurrentView('day');
									}
								}}>
								{y}
							</Cell>
						);
					})}
			</Body>
		</Wrapper>
	);
}
