import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
	display: flex;
	align-items: flex-start;
	line-height: 24px;
	font-size: 17px;
	margin-bottom: 20px;
	user-select: none;
	cursor: pointer;
	width: -webkit-fill-available;

	input {
		position: absolute;
		opacity: 0;
		height: 1px;
		width: 1px;
		margin: 0;
	}

	.radio {
		position: relative;
		-webkit-appearance: none;
		appearance: none;
		height: 24px;
		flex: 0 0 24px;
		margin-right: 10px;
		border-radius: 50%;
		transform: translateZ(0);
		background: ${p => p.theme.colors.grey500};
		transition: all 0.2s ease-in-out;

		&:after {
			content: '';
			-webkit-appearance: none;
			appearance: none;
			position: absolute;
			top: 2px;
			left: 2px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: white;
			border: 3px solid white;
			transition: all 0.2s ease-in-out;
		}
	}

	&:hover input ~ .radio {
		background: ${p => p.theme.colors.grey700};
	}

	input:checked ~ .radio {
		background-color: ${p => p.theme.colors.blue600};
		border-color: ${p => p.theme.colors.blue600};

		&:after {
			background-color: transparent;
		}
	}

	&:hover input:checked:not(:disabled) ~ .radio {
		background-color: ${p => p.theme.colors.blue700};
	}

	input:disabled ~ .label {
		cursor: no-drop;
		color: ${p => p.theme.colors.grey500};
	}
	input:disabled ~ .radio {
		cursor: no-drop;
		border-color: ${p => p.theme.colors.grey500};
	}
`;

const HelpText = styled.div`
	color: ${p => p.theme.colors.grey700};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin-top: 5px;
`;

const Radio = forwardRef(({ label, helpText, children, ...props }, ref) => {
	return (
		<Wrapper>
			<input type="radio" {...props} ref={ref} />

			<div className="radio" />

			<div className="label">
				<span>{label || children}</span>
				{helpText && <HelpText>{helpText}</HelpText>}
			</div>
		</Wrapper>
	);
});

export default Radio;
