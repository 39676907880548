import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import useSaveConsent, {
	ConsentCheckbox,
	PrivacyPolicyCheckbox,
} from 'components/consent-portal/useSaveConsent';
import { useAnalytics, delay } from 'context/AnalyticsProvider';
import InputField from 'components/forms/InputField';
import Button from 'components/forms/Button';
import Error from 'components/error/Error';
import TitleAndText from 'parts/title-and-text/TitleAndText';

const Wrapper = styled.div`
	text-align: left;

	.component__title-and-text {
		.text {
			margin-bottom: 20px;
		}
	}
`;

const Row = styled.div`
	&:first-of-type {
		margin-top: 20px;
	}
	${p =>
		p.$fieldCount === 2 &&
		p.theme.media.medium(css`
			display: flex;
			align-items: center;
			justify-content: space-between;
			> div {
				flex: 1;
				&:first-of-type {
					margin-right: 10px;
				}
				&:last-of-type {
					margin-left: 10px;
				}
			}
		`)}
`;

const ThanksWrap = styled.div`
	padding: 45px 0;
	text-align: ${p => (p.$centered ? 'center' : 'left')};
	h3 {
		margin: 0;
	}
`;

/**
 * Represents a custom lead form
 * @param {...object} props - The properties for the form
 * @param {string} [title] - The title of the form
 * @param {string} [text] - The text of the form
 * @param {string} [event] - The event of the form
 * @param {string} [subscription='Markedsføring'] - The subscription type title
 * @param {string} [className='lead-form'] - The class name of the form
 * @param {array} [fields=['name', 'email', 'phone']] - The fields of the form
 * @param {function} [onSubmit] - The function to call when submitting the form
 * @param {boolean} [loading] - The loading state of the form
 * @param {string} [error] - The error from the form
 * @param {string} [resolver] - The name of the resolver for the form
 * @param {boolean} [submitted] - The submitted state of the form
 * @param {object} [submitTexts] - The submit texts of the form
 * @param {...object} [props] - The properties for the form
 * @returns {HTMLElement} The custom lead form
 **/
export default function LeadForm({
	title,
	text,
	event,
	subscription = 'Markedsføring',
	className = 'lead-form',
	fields = ['name', 'email', 'phone'],
	onSubmit,
	loading,
	error,
	resolver = '',
	submitted = false,
	submitTexts = {
		submitting: 'Sender henvendelse',
		submit: 'Kontakt meg gjerne',
		submitted: 'Tusen takk for din henvendelse.',
	},
	centeredThankYou = true,
	...props
}) {
	const [submitting, setSubmitting] = useState(false);
	const { saveConsent } = useSaveConsent();
	const { identify } = useAnalytics();

	const handleOnSubmit = async e => {
		e.preventDefault();

		try {
			if (!onSubmit) {
				throw new Error('No onSubmit function provided');
			}

			setSubmitting(true);

			const values = {
				marketing: e?.target?.marketing?.checked,
			};

			// Requried fields
			const requiredFields = ['name', 'email'];

			// Get values from fields and check if they are required
			fields?.forEach(field => {
				values[field] = e?.target?.[field]?.value;

				// If field is required and has no value, throw error
				if (
					requiredFields.includes(field) &&
					!e?.target?.[field]?.value
				) {
					throw new Error(
						`${
							field.charAt(0).toUpperCase() + field.slice(1)
						} is required`
					);
				}
			});

			// Save consent if user has accepted marketing
			if (values?.marketing) {
				await saveConsent({
					event: event || title,
					email: values?.email,
					phone: values?.phone,
					subscription,
					action: 'Form Submitted',
				});
			}

			// Segment identify user
			await identify({
				name: values?.name,
				phone: values?.phone,
				email: values?.email,
			});

			// Delay
			await delay();

			// Call onSubmit function
			onSubmit({ ...values });
		} catch (e) {
			setSubmitting(false);
			console.error('Error submitting form', e);
		}
	};

	// Check if fields are included
	const rowFieldsCount = fields?.filter(f => f === 'name' || f === 'email');

	return (
		<Wrapper>
			{(!submitted && (
				<form onSubmit={e => handleOnSubmit(e)}>
					{props?.hidetitle !== 'true' && (
						<TitleAndText
							title={title}
							text={text}
							headinglevel={props?.headinglevel || 'h3'}
							nested={true}
						/>
					)}

					<Row $fieldCount={rowFieldsCount?.length || 1}>
						{fields?.includes('name') && (
							<InputField
								id="name"
								name="name"
								label="Navn"
								placeholder="Navn"
								data-cy="lead-input-name"
								required
							/>
						)}
						{fields?.includes('email') && (
							<InputField
								id="email"
								name="email"
								label="E-post"
								type="email"
								placeholder="E-post"
								data-cy="lead-input-email"
								required
							/>
						)}
					</Row>

					{fields?.includes('phone') && (
						<InputField
							id="phone"
							name="phone"
							type="tel"
							label="Telefon"
							placeholder="Telefon"
							data-cy="lead-input-phone"
						/>
					)}

					<ConsentCheckbox id="marketing" name="marketing" />

					<PrivacyPolicyCheckbox />

					<Button
						type="submit"
						data-cy={`${className}-submit`}
						title="Send inn"
						loading={
							(!!(submitting || loading) && 'true') || 'false'
						}
						disabled={submitting || loading}>
						{(submitting && submitTexts?.submitting) ||
							submitTexts?.submit}
					</Button>

					{!(loading || submitting) && error && (
						<Error
							text="Noe gikk galt med innsendingen. Prøv igjen senere."
							resolver={resolver}
							errorMessage={error}
						/>
					)}
				</form>
			)) || (
				<ThanksWrap
					data-cy={`${className}-success`}
					$centered={centeredThankYou}>
					<h3>{submitTexts?.submitted}</h3>
				</ThanksWrap>
			)}
		</Wrapper>
	);
}
