import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';

import Calendar from './Calendar';
import DatePickerProvider, { useDatePicker } from './Provider';
import InputField from 'components/forms/InputField';

/* Init norwegian locale */
dayjs.locale('nb');

/* Init dayjs plugin */
dayjs.extend(customParseFormat);

const Wrapper = styled.div`
	position: relative;
`;

const Description = styled.small`
	margin: 10px 0 20px;
	display: block;
	font-size: 14px;
	line-height: 20px;
	font-style: italic;
`;

export default function DatePicker({ onChange, onBlur, ...props }) {
	return (
		<DatePickerProvider {...props}>
			<PickerWrapper onChange={onChange} onBlur={onBlur} {...props} />
		</DatePickerProvider>
	);
}

function PickerWrapper({ onChange, onBlur, desc, ...props }) {
	const {
		date,
		handleSelectDate,
		showCalendar,
		setShowCalendar,
		isSameOrAfterDay,
		isSameOrBeforeDay,
		dateIsNotIncluded,
	} = useDatePicker();

	const [invalidDate, setInvalidDate] = useState(props?.error || null);
	const datePickerRef = useRef(null);

	const show = props?.fixedCalendar || showCalendar;

	/**
	 * Handle keydown
	 * @param {object} event - The event object
	 * @returns {void}
	 **/
	function handleKeyDown(event) {
		if (props?.fixedCalendar || event.key !== 'Escape') return;
		setShowCalendar(false);
	}

	/**
	 * Handle click outside calendar-box
	 * @param {object} event - The event object
	 * @returns {void}
	 **/
	function handleClickOutside(event) {
		if (props?.fixedCalendar) return;
		if (
			datePickerRef?.current &&
			!datePickerRef.current.contains(event.target)
		) {
			setShowCalendar(false);
		}
	}

	/**
	 * Update date
	 * @param {string} value - The date string
	 * @param {string} dateFormat - The date format
	 * @returns {void}
	 **/
	function updateDate(value, dateFormat) {
		setInvalidDate(null);

		// If value is less than 8 characters, return
		if (!value || value?.length < 8) return;

		// Validate date format
		const validatedDate = validateDateString(value, dateFormat);

		// Unvalid date format
		if (!validatedDate) {
			setInvalidDate('Ugyldig datoformat');
			return;
		}

		// If date is not 10 characters long, return
		if (
			validatedDate &&
			!validatedDate?.format(dateFormat)?.length === 10
		) {
			return;
		}

		// Define error messages
		const dateErrorMessages = {
			blockPastDates: [
				'Kun fremtidige datoer er tillatt',
				() => props.blockPastDates && isSameOrAfterDay(validatedDate),
			],
			blockFutureDates: [
				'Kun datoer tilbake i tid er tillatt',
				() =>
					props.blockFutureDates && isSameOrBeforeDay(validatedDate),
			],
			blockTomorrow: [
				'Ikke mulig å velge morgendagen',
				() => validatedDate.isSameOrBefore(dayjs().add(1, 'day')),
			],
			availableDates: [
				'Ikke mulig å velge denne datoen',
				() =>
					props?.availableDates?.length > 0 &&
					dateIsNotIncluded(props?.availableDates, validatedDate),
			],
			maxDate: [
				props?.error || 'Ikke mulig å velge denne datoen',
				() =>
					props?.maxDate &&
					validatedDate.isAfter(props?.maxDate, 'day'),
			],
			minDate: [
				props?.error || 'Ikke mulig å velge denne datoen',
				() =>
					props?.minDate &&
					validatedDate.isAfter(props?.minDate, 'day'),
			],
		};

		// Loop through error messages and set invalid date if condition is met
		for (const [prop, [message, condition]] of Object.entries(
			dateErrorMessages
		)) {
			if (props[prop] && condition()) {
				setInvalidDate(message);
				handleSelectDate({ value: null });
				return;
			}
		}
	}

	/**
	 * Debounce input typing
	 * @param {string} value - The date string
	 * @param {string} dateFormat - The date format
	 * @returns {void}
	 */
	const handleInputTyping = debounce((value, dateFormat) => {
		setInvalidDate(null);

		// If onChange is set, call it
		if (onChange) onChange(value);

		// Update date
		updateDate(value, dateFormat);

		// If onBlur is set, call it
		if (onBlur) onBlur(value);

		// Validate date format
		const validatedDate = validateDateString(value, dateFormat);

		// Unvalid date format
		if (!validatedDate || !validatedDate?.isValid()) {
			setInvalidDate('Ugyldig datoformat');
		} else {
			// Set selected date
			handleSelectDate({ value: validatedDate });
		}
	}, 1000);

	/* Update state on load */
	useEffect(() => {
		if (!props?.value) return;

		updateDate(props?.value, props?.dateFormat);

		const validatedDate = validateDateString(
			props?.value,
			props?.dateFormat
		);

		// Set selected date
		handleSelectDate({
			value: validatedDate,
		});

		//eslint-disable-next-line
	}, [props?.value]);

	useEffect(() => {
		if (props.fixedCalendar) return;
		document.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keydown', handleKeyDown, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
			document.removeEventListener('keydown', handleKeyDown, true);
		};
		//eslint-disable-next-line
	}, []);

	return (
		<Wrapper ref={datePickerRef} className="datepicker">
			{!props?.hideInput && (
				<InputField
					id={props?.id}
					key={date}
					name={props?.name}
					label={props?.label}
					defaultValue={
						(date &&
							validateDateString(date) &&
							dayjs(date).format(
								props?.dateFormat || 'DD/MM/YYYY'
							)) ||
						''
					}
					helpText={props?.helpText}
					error={invalidDate}
					onChange={e => {
						e.preventDefault();

						handleInputTyping(
							e?.target?.value,
							props?.dateFormat || 'DD/MM/YYYY'
						);
					}}
					onFocus={() => {
						if (props?.fixedCalendar) return;
						setShowCalendar(true);
					}}
					onBlur={() => {
						if (props?.onBlur) props?.onBlur();
					}}
					icon={faCalendarDays}
					data-cy={
						(props?.dataCy && props?.dataCy) || 'datepicker-input'
					}
					required={props?.required}
				/>
			)}

			{desc && <Description>{desc}</Description>}

			{show && (
				<Calendar
					onChange={value => {
						const date = dayjs(value).format(
							props?.dateFormat || 'DD/MM/YYYY'
						);

						handleInputTyping(
							date,
							props?.dateFormat || 'DD/MM/YYYY'
						);
					}}
					invalidDate={invalidDate}
					{...props}
				/>
			)}
		</Wrapper>
	);
}

/**
 * Validate date string
 * @param {string} value - The date string to validate
 * @param {string} finalFormat - The final date format
 * @returns {object} - The date object
 */
export function validateDateString(value = '', finalFormat = 'DD/MM/YYYY') {
	if (!value || !finalFormat) return;

	const validFormats = [
		'DD.MM.YYYY',
		'DD/MM/YYYY',
		'DD-MM-YYYY',
		'DDMMYYYY',
		'D. MMMM YYYY',
	];

	const validFormat = validFormats.find(format =>
		dayjs(value, format, true).isValid()
	);

	return validFormat ? dayjs(value, finalFormat) : false;
}
