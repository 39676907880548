import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const Range = styled.input`
	/* Reset the default appearance */
	appearance: none;
	-webkit-appearance: none;

	outline: none;
	background: ${p =>
		(p.mode === 'dark' && 'rgba(255, 255, 255, 0.35)') ||
		'rgba(0, 0, 0, 0.1)'};
	border-radius: 20px;
	width: 100%;
	border: 0;
	flex-grow: 1;
	margin: 0;
	padding: 0;
	height: 10px;
	min-height: auto !important;
	&:focus {
		background: ${p =>
			(p.mode === 'dark' && 'rgba(255, 255, 255, 0.35)') ||
			'rgba(0, 0, 0, 0.1)'};
	}

	// Scale thumb on focus
	&:focus-within::-webkit-slider-thumb,
	&:focus::-webkit-slider-thumb {
		transform: scale(1.2);
	}

	// Progress bar
	${p =>
		p?.progress &&
		p.progress > 0 &&
		css`
			background-image: ${p =>
				`linear-gradient(to right, ${p.theme.colors.blue400} 0%, ${
					p.theme.colors.blue400
				} ${p.progress}%, ${
					(p.mode === 'dark' && 'rgba(255, 255, 255, 0.35)') ||
					'rgba(0, 0, 0, 0.1) '
				} ${p.progress}%, ${
					(p.mode === 'dark' && 'rgba(255, 255, 255, 0.35)') ||
					'rgba(0, 0, 0, 0.1) '
				} 100%)`};
			&:focus {
				background-image: ${p =>
					`linear-gradient(to right, ${p.theme.colors.blue400} 0%, ${
						p.theme.colors.blue400
					} ${p.progress}%, ${
						(p.mode === 'dark' && 'rgba(255, 255, 255, 0.35)') ||
						'rgba(0, 0, 0, 0.1) '
					} ${p.progress}%, ${
						(p.mode === 'dark' && 'rgba(255, 255, 255, 0.35)') ||
						'rgba(0, 0, 0, 0.1) '
					} 100%)`};
			}
		`}

	${p =>
		p.theme.media.smallOnly(css`
			margin: 10px 10px 0;
		`)}

	// Entire bar
    &::-moz-range-track,
	&::-webkit-slider-runnable-track {
		background: transparent;
	}

	// Circle / thumb
	&::-webkit-slider-thumb {
		appearance: none !important;
		-webkit-appearance: none !important;
		border-radius: 20px;
		background-color: white;
		width: 20px;
		height: 20px;
		cursor: pointer;
		transition: transform 350ms ease;
		box-shadow: ${p => p.theme.utils.boxShadow};
		&:hover,
		&:focus {
			transform: scale(1.2);
		}
	}
`;

/**
 * Input range component
 * @param {Object} props
 * @param {string} props.min - Minimum value
 * @param {string} props.max - Maximum value
 * @param {number} props.progress - Current progress
 * @param {string} props.mode - Color mode (light/dark)
 * @param {function} props.onChange - On change callback
 * @param {Object} props.ref - Ref
 * @returns {JSX.Element}
 **/
const InputRange = forwardRef(
	(
		{
			min = '0',
			max = '100',
			progress = 0,
			mode = 'light',
			onChange = () => {},
			...props
		},
		ref
	) => {
		return (
			<Range
				type="range"
				ref={ref}
				min={min}
				max={max}
				value={progress}
				onChange={e => onChange(e)}
				progress={progress}
				mode={mode}
				{...props}
			/>
		);
	}
);

export default InputRange;
